import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import { DatePicker } from "@material-ui/pickers";
import Swal from 'sweetalert2';

import {
	withStyles,
	Grid,
	CircularProgress,
	InputLabel,
	TextField,
	CardContent,
	Card,
	Button,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import {
	Portlet,
	PortletContent,
	Table,
} from 'components';


//Services
import { WithNotifications } from 'hoc';
import transfersService from 'services/transfersService';

//Assets
import styles from './styles';

function ReceiveTransferForm(props) {
	const {
		classes,
		title,
		hideLayout,
		onSave,
	} = props;

	const [loading] = useState(false);
	const [item, setItem] = useState(null);
	const [details, setDetails] = useState([]);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const [id] = useState(props.id);
	const [saving, setSaving] = useState(false);
	const [columns, setColumns] = useState();

	const refs = {};

	const init = async () => { };

	const loadItem = async () => {
		if (!id) {
			return;
		}

		try {
			const response = await transfersService.getById(id);

			if (response && response.data && response.data.data) {
				setItem(response.data.data);
				setDetails(response.data.data.details.map((i, index) => ({
					...i,
					index,
				})));
			}
		}
		catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		setColumns([
			{
				title: "Artículo",
				field: "itemId",
				editable: 'never',
			},
			{
				title: "Descripción",
				field: "itemDescription",
				editable: 'never',
			},
			{
				title: "UM",
				field: "itemMeasurementUnit",
				type: 'numeric',
				editable: 'never',
			},
			{
				title: "Cantidad solicitada",
				field: "quantityRequested",
				type: 'numeric',
				editable: 'never',
				render: row => <span>{row.quantityRequested}</span>
			},
			{
				title: "Cantidad a transferir",
				field: 'quantityReceived',
				render: row => renderRowInput(row)
			},
			// {
			// 	title: "Notas",
			// 	field: 'notes',
			// 	render: row => renderRowCommentInput(row)
			// },
		]);
	}, [details]);

	useEffect(() => {
		loadItem();
	}, [id]);

	const save = async () => {
		const model = {
			id: id,
			...item,
			details:
				details.map((i) => ({
					...i,
					TransferReceiptId: id,
					quantityRequested: parseFloat(i.quantityRequested || 0),
					totalAmount: parseFloat(i.quantityReceived || 0),
				}))
		};

		if (saving || !details || !details.length) {
			return;
		}

		if (details.filter(x => x.quantityReceived > 0).length == 0) {
			props.addNotification({ message: 'Es necesario ingresar cantidad en algun artículo', options: { variant: "error" } });
			setSaving(false);
			return;
		}

		const result = await Swal.fire({
			title: "Ingresa el código secreto",
			input: "text",
			inputAttributes: {
				autocapitalize: "off"
			},
			showCancelButton: true,
			confirmButtonText: "Validar",
			cancelButtonText: "Cancelar",
			showLoaderOnConfirm: true,
			preConfirm: async (code) => {
				return code;
			},
			allowOutsideClick: () => !Swal.isLoading()
		});

		if (!result.value) {
			return;
		}

		model.secretCode = result.value;

		setSaving(true);

		try {
			const response = await transfersService.receive(model);

			if (!response || !response.data || !response.data.data || !response.data.data.result) {
				props.addNotification({ message: response.data.data, options: { variant: "error" } });
				setSaving(false);
				return;
			}
			else {
				props.addNotification({ message: 'Se han guardado los datos', options: { variant: "success" } });
				onSave();
			}
		} catch (e) {
			console.log(e);
		}

		setSaving(false);
	};

	// const getRowTotal = (detail, type) => {
	// 	if (type === 'R' && !detail.quantityReceived || isNaN(detail.quantityReceived)) {
	// 		detail.newTotal = 0;
	// 		return 0;
	// 	}

	// 	if (type === 'G' && !detail.quantityFreeExtra || isNaN(detail.quantityFreeExtra)) {
	// 		return 0;
	// 	}

	// 	if (type === 'D' && !detail.quantityReturned || isNaN(detail.quantityReturned)) {
	// 		return 0;
	// 	}


	// 	const newValue = parseFloat(type === 'R' ? detail.quantityReceived : type === 'G' ? detail.quantityFreeExtra : detail.quantityReturned);
	// 	const newTotal = (newValue * detail.price).toFixed(2);
	// 	detail.newTotal = newTotal;
	// 	detail.totalReceived = detail.quantityReceived + detail.quantityFreeExtra - detail.quantityReturned;
	// 	return newTotal;
	// };

	const renderRowInput = (row) => {
		const type = 'B';
		return (
			<TextField
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantityReceived}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.id == row.id);
					detail.quantityReceived = e.target.value;
					// getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	// const renderRowCommentInput = (row) => {
	// 	return (
	// 		<TextField
	// 			type="text"
	// 			value={row.notes}
	// 			multiline
	// 			fullWidth
	// 			margin="dense"
	// 			onChange={(e) => {
	// 				const newDetails = [...details];
	// 				const detail = newDetails.find(x => x.id == row.id);
	// 				detail.notes = e.target.value;
	// 				setDetails(newDetails);
	// 			}}
	// 		/>
	// 	);
	// };

	const previousFocus = (index, type) => {
		const prev = index - 1;

		if (prev < 0) {
			return;
		}

		if (refs[`input${prev}-${type}`] != null) {
			const input = refs[`input${prev}-${type}`];
			input.focus();
			input.setSelectionRange(0, input.value.length);
		}
		else {
			previousFocus(prev, type);
		}
	};

	const nextFocus = (index, type) => {
		let next = index + 1;

		if (next > details.length) {
			return;
		}

		if (refs[`input${next}-${type}`] != null) {
			const input = refs[`input${next}-${type}`];
			input.focus();
		}
		else {
			nextFocus(next, type);
		}
	};

	const copyValues = () => {
		let newDetails = [...details];
		newDetails.forEach((i) => {
			i.quantityReceived = i.quantityRequested;
		});

		setDetails(newDetails);
	};

	const renderForm = () => {
		if (!item)
			return null;

		return (
			<form className="contracts-form" key={id}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} lg={4}>
										<TextField
											disabled
											fullWidth
											label="Sucursal"
											value={item.branch.supplierName}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item xs={12} lg={4} alignContent="center" className={classes.item}>
										<Grid
											direction="column"
											justify="center"
										>
											<InputLabel>Fecha</InputLabel>
											<DatePicker
												disabled
												format="DD/MM/YYYY"
												value={deliveryDate}
												onChange={date => setDeliveryDate(date)}
												autoOk
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid item className={classes.item} xs={12} lg={4}>
										<TextField
											disabled
											fullWidth
											label="Solicita"
											value={item.createdByName}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={4}>
										<TextField
											fullWidth
											label="Almacen Origen"
											value={item.originStorage}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={4}>
										<TextField
											fullWidth
											label="Almacen Destino"
											value={item.destinationStorage}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={4}>
										<TextField
											fullWidth
											label="Notas"
											value={item.notes}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.root} spacing={1}>
							<Grid item className={classes.item} xs={12}>
								<Table
									key={id}
									title="Detalle"
									isLoading={loading}
									columns={columns}
									items={details}
									options={{
										paging: false,
										search: false,
										selection: false,
									}}
									actions={[
										{
											icon: 'file_copy',
											isFreeAction: true,
											tooltip: "Llenar cantidades",
											onClick: copyValues
										}
									]}
								/>
							</Grid>
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{details && details.length > 0 &&
									<Button
										variant="outlined"
										color="primary"
										onClick={save}
										style={{ height: 30 }}
									>
										Procesar transferencia
									</Button>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
					&& <CircularProgress />
				}
				{renderForm()}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	if (hideLayout) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Orden de compra"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

ReceiveTransferForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

ReceiveTransferForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(withRouter(withStyles(styles)(ReceiveTransferForm)));
