import Api from "./Api";

class TransformationsService extends Api {
	constructor(controller) {
		super(controller);
	}

	create = model => {
		return this.API.post(`${this.controller}`, model);
	};

	update = model => {
		return this.API.post(`${this.controller}/update-order`, model);
	};

	validate = model => {
		return this.API.post(`${this.controller}/validate-order?storageId=${model.storageId}&supplierId=${model.branchId}`, model);
	};

	receive = model => {
		return this.API.post(`transfer-receipt`, model);
	};

	search = ({ branchId, startDate, endDate, statusId, itemId }) => {
		let url = `${this.controller}?branchId=${branchId}&startDate=${startDate.toJSON()}&endDate=${endDate.toJSON()}`;

		if (statusId) {
			url += `&statusId=${statusId}`;
		}

		if (itemId) {
			url += `&itemId=${itemId}`;
		}

		return this.API.get(url);
	};

	getById = (id) => {
		return this.API.get(`${this.controller}/GetById?id=${id}`);
	};

	getPosibleStorageItems = (storageId, destStorageId) => {
		return this.API.get(`${this.controller}/GetItemsTransferableBetweenStorages/${storageId}/${destStorageId}`);
	};
}

let service = new TransformationsService("production-order");

export default service;