import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography
} from '@material-ui/core';

// Material icons
import {
    DashboardOutlined as DashboardIcon,
    WorkOutlined as AdminIcon,
    ExpandLess,
    ExpandMore,
    CheckOutlined,
    FontDownloadOutlined,
    PlaylistAddCheckOutlined,
    ListOutlined,
    AssignmentReturnOutlined,
    ClassOutlined,
    SearchOutlined,
    FolderOutlined,
    DirectionsCarOutlined,
    BusinessOutlined,
    LocalShippingOutlined,
    RecentActors,
    AssessmentOutlined,
    CloudDownloadOutlined,
    AnnouncementOutlined,
    AddCircleOutlined,
    PersonOutline,
    ListAltOutlined,
    Store,
    StoreOutlined,
    ArrowForwardOutlined,
    AssignmentTurnedInOutlined,
    TransformOutlined,
    VisibilityOutlined,
    EditOutlined,
} from '@material-ui/icons';

// SEA components
import Token from '../../../../contract/responses/token';

// Component styles
import styles from './styles';

import WithSession from '../../../../hoc/WithSession';
import WithAuth from '../../../../hoc/WithAuth';

class Sidebar extends Component {
    state = {
        administrationOpen: false,
        ticketsOpen: false,
        reportsOpen: false,
        newsOpen: false,
        purchaseOrdersOpen: false,
        inventoriesOpen: false,
        transformationsOpen: false,
    };

    hasPermission = businessFunctionId => {
        return (
            this.props.permissions &&
            this.props.permissions.find(x => x.bfId == businessFunctionId)
        );
    };

    getCompanyLogo = () => {
        var logosFolder = '/images/logos/';
        return logosFolder + (this.props.settings && this.props.settings.logo ? this.props.settings.logo : "companyLogo.png");
    }

    render() {
        const {
            classes,
            className,
            session,
            permissionsLoaded,
        } = this.props;

        const {
            administrationOpen,
            checkListOpen,
            catalogsOpen,
            reportsOpen,
            newsOpen,
            purchaseOrdersOpen,
            inventoriesOpen,
            transformationsOpen,
        } = this.state;

        if (!session || !permissionsLoaded) {
            return null;
        }

        const rootClassName = classNames(classes.root, className);

        return (
            <nav className={rootClassName}>
                <div className={classes.logoWrapper}>
                    <Link className={classes.logoLink} to="/">
                        <img
                            alt="Logo"
                            className={classes.logoImage}
                            src={this.getCompanyLogo()}
                        />
                    </Link>
                </div>
                <Divider className={classes.logoDivider} />
                <div className={classes.profile}>
                    <Link className={classes.logoLink} to="/profile">
                        <Avatar
                            alt={session.userName}
                            className={classes.avatar}
                            src="/images/avatars/avatar_generic_0.png"
                        />
                        <Typography className={classes.nameText} variant="h6">
                            {session.displayName} ({session.userName})
                        </Typography>
                        <Typography className={classes.bioText} variant="caption">
                            {session.email}
                        </Typography>
                    </Link>
                </div>
                <Divider className={classes.profileDivider} />
                <List component="div" disablePadding>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/dashboard"
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Dashboard"
                        />
                    </ListItem>
                    {
                        this.hasPermission(127) &&
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLink}
                            to="/clients-contracts"
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <PersonOutline />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary="Contratos"
                            />
                        </ListItem>
                    }
                    {
                        this.hasPermission(116) &&
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLink}
                            to="/lunchrooms-info"
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <ClassOutlined />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary="Unidades"
                            />
                        </ListItem>
                    }
                    {
                        this.hasPermission(110) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ catalogsOpen: !catalogsOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <FolderOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Catálogos"
                                />
                                {catalogsOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={catalogsOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/branches"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Store />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Sucursales"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/suppliers"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <RecentActors />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Proveedores"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/routes"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <BusinessOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Rutas"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/vehicles"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <DirectionsCarOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Vehiculos"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/items"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <ListAltOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Artículos"
                                        />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(69) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ administrationOpen: !administrationOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AdminIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Requisiciones"
                                />
                                {administrationOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={administrationOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/search-requisitions"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <SearchOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Buscar"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/receive-requisitions"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <CheckOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Recibir requisiciones"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/confirm-requisitions"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <CheckOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Surtir requisiciones"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/supply-items"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <CheckOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Surtir articulos"
                                        />
                                    </ListItem>
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLink}
                                        to="/download-layout"
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <FontDownloadOutlined />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="Descargar Layout"
                                        />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(131) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ purchaseOrdersOpen: !purchaseOrdersOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <StoreOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Ordenes de compra"
                                />
                                {purchaseOrdersOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={purchaseOrdersOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    {
                                        this.hasPermission(131) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/purchase-orders/create"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <StoreOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Crear"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(133) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/purchase-orders/receipt"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <CheckOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Recibir"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(132) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/purchase-orders/search"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <SearchOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Buscador"
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(134) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ inventoriesOpen: !inventoriesOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <StoreOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Inventarios"
                                />
                                {inventoriesOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={inventoriesOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    {
                                        this.hasPermission(135) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/inventories/search"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <SearchOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Buscar"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(136) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/inventories/create-transfer"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <ArrowForwardOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Transferencias"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(137) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/inventories/receive-transfer"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <AssignmentTurnedInOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Aceptar Transferencia"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(142) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/inventories/adjustment"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <EditOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Ajustar Inventario"
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(138) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ transformationsOpen: !transformationsOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <TransformOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Transformaciones"
                                />
                                {transformationsOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={transformationsOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    {
                                        this.hasPermission(138) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/transformations/search"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <ListOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Transformaciones"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(138) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/transformations/requested"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <VisibilityOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Solicitado"
                                            />
                                        </ListItem>
                                    }
                                    
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(111) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ checkListOpen: !checkListOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <ListOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Chofer"
                                />
                                {checkListOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={checkListOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    {
                                        this.hasPermission(115) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/route-check-list"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <PlaylistAddCheckOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="CheckList Rutas"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(113) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/driver-returns"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <AssignmentReturnOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Devoluciones"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(114) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/search-deliveries"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <LocalShippingOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Buscar traslados"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(114) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/consigment-notes"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <LocalShippingOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Crear traslado"
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(117) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ reportsOpen: !reportsOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssessmentOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Reportes"
                                />
                                {reportsOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={reportsOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    {
                                        this.hasPermission(118) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/detailed-rq-report"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <CloudDownloadOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Reporte detallado de requisiciones"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(119) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/concentrated-rq-report"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <CloudDownloadOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Reporte concentrado de requisiciones"
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                    {
                        this.hasPermission(120) &&
                        <React.Fragment>
                            <ListItem
                                //activeClassName={classes.activeListItem}
                                //component={}
                                className={classes.listItem}
                                onClick={() =>
                                    this.setState({ newsOpen: !newsOpen })
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AnnouncementOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary="Avisos"
                                />
                                {newsOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={newsOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classes.groupItems}
                            >
                                <List component="div" disablePadding>
                                    {
                                        this.hasPermission(122) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/news-list"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <ListOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Avisos"
                                            />
                                        </ListItem>
                                    }
                                    {
                                        this.hasPermission(123) &&
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            className={classes.listItem}
                                            component={NavLink}
                                            to="/news"
                                        >
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <AddCircleOutlined />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary="Crear aviso"
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    }
                </List>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    session: PropTypes.object,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

Sidebar.defaultProps = {
    session: new Token()
};

export default withStyles(styles)(WithAuth(WithSession(Sidebar)));
