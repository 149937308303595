import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import { DatePicker } from "@material-ui/pickers";

import { formatNumber } from 'utils/numbers';

import {
	withStyles,
	Grid,
	CircularProgress,
	InputLabel,
	TextField,
	CardContent,
	Card,
	Button,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import {
	Portlet,
	PortletContent,
	Select,
	Table,
} from 'components';


//Services
import { WithNotifications } from 'hoc';
import suppliersService from 'services/suppliersService';
import purchasesService from 'services/purchasesService';
import purchasesReceiptService from 'services/purchasesReceiptService';

//Assets
import styles from './styles';

function PurchaseOrderForm(props) {
	const {
		classes,
		title,
		hideLayout,
		onSave,
	} = props;

	const [loading] = useState(false);
	const [branches, setBranches] = useState([]);
	const [details, setDetails] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [branchId, setBranchId] = useState(null);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const [supplierId, setSupplierId] = useState(null);
	const [receiptType, setReceiptType] = useState(5);
	const [statusId, setStatusId] = useState(1);
	const [tipoPedidoId, setTipoPedidoId] = useState(1);
	const [id] = useState(props.id);
	const [saving, setSaving] = useState(false);
	const [receiptTypes] = useState([
		{
			value: 4,
			label: "Parcial"
		},
		{
			value: 5,
			label: "Final"
		},
	]);
	const [columns, setColumns] = useState();

	const [statuses] = useState([
		{
			value: 1,
			label: "Nueva",
		},
		{
			value: 2,
			label: "Pendiente de revisión",
		},
		{
			value: 3,
			label: "Confirmada y bloqueada para edicion",
		},
		{
			value: 4,
			label: "Recibida Parcialmente",
		},
		{
			value: 5,
			label: "Recibida",
		},
		{
			value: 6,
			label: "Cancelada",
		}
	]);

	const [tiposPedido] = useState([
		{
			value: 1,
			label: "Materia Prima",
		},
		{
			value: 2,
			label: "Gasto",
		},
		{
			value: 3,
			label: "AC Fijo",
		},
		{
			value: 4,
			label: "Empleado",
		},
		{
			value: 5,
			label: "Fijos",
		},
		{
			value: 6,
			label: "Perifericos",
		},
		{
			value: 7,
			label: "Urgente",
		},
	]);

	const refs = {};


	const init = async () => {
		// loadItemFromUrl();

		suppliersService.getInternal().then(response => {
			setBranches(response && response.data ? response.data.data.map((i) => ({ value: i.supplierId, label: i.supplierName })) : []);
		});

		suppliersService.getExternal().then(response => {
			setSuppliers(response && response.data ? [{ value: 0, label: 'Mejor proveedor' }, ...response.data.data.map((i) => ({ value: i.supplierId, label: i.supplierName }))] : []);
		});
	};

	const loadItem = async () => {
		if (!id) {
			return;
		}

		try {
			const response = await purchasesService.getById(id);

			if (response && response.data && response.data.data) {
				setBranchId(response.data.data.branchId);
				setSupplierId(response.data.data.supplierId);
				setDeliveryDate(new Date(response.data.data.deliveryDate));
				setStatusId(response.data.data.statusId);
				//setTipoPedidoId();
				setDetails(response.data.data.purchaseDetails.map((i, index) => ({
					...i,
					id: i.purchaseDetailId,
					index,
					description: i.itemDescription,
					measurementUnit: i.itemMeasurementUnit,
					quantity: i.itemQuantity,
					cost: i.itemUnitPrice,
					observations: i.notes,
					supplier: response.data.data.supplierName,
					quantityReceived: "",
					quantityFreeExtra: "",
					quantityReturned: "",
					comments: "",
				})));

				// await getStorages();
				// setTimeout(calcSummary, 1000);
			}
			// const expensesP = clientsService.getExpenses(clientId);
			// const documentsP = clientsService.getDocuments(clientId);
			// const lunchRoomsP = clientsService.getLunchRooms(clientId);
			// const itemPricesReportP = clientsService.getItemPricesReport(clientId);
			// const clientGroupsP = historicalPriceService.getClientGroups(clientId);
			// const menuP = clientsService.getMenu(clientId);
			// const serviceP = clientsService.getService(clientId);

			// const results = {
			// 	expenses: await expensesP,
			// 	documents: await documentsP,
			// 	lunchRooms: await lunchRoomsP,
			// 	//salesReport: await salesReportP,
			// 	itemPricesReport: await itemPricesReportP,
			// 	clientGroups: await clientGroupsP,
			// 	menu: await menuP,
			// 	service: await serviceP,
			// };

			// const newItem = {
			// 	...client,
			// 	loaded: true,
			// 	expenses: results.expenses.data.data && results.expenses.data.data.length ? results.expenses.data.data : [],
			// 	documents: results.documents.data.data && results.documents.data.data.length ? results.documents.data.data : [],
			// 	lunchRooms: results.lunchRooms.data.data && results.lunchRooms.data.data.length
			// 		? [{ lunchRoomId: 0, lunchRoomName: "Todas" }, ...results.lunchRooms.data.data]
			// 		: [{ lunchRoomId: 0, lunchRoomName: "Todas" }],
			// 	salesReport: [],
			// 	itemPricesReport: results.itemPricesReport.data.data && results.itemPricesReport.data.data.length ? results.itemPricesReport.data.data : [],
			// 	clientGroups: results.clientGroups.data.data && results.clientGroups.data.data.length ? results.clientGroups.data.data : [],
			// 	menu: results.menu.data && results.menu.data.data ? results.menu.data.data : { ...emptyItem.menu },
			// 	service: results.service.data && results.service.data.data ? results.service.data.data : { ...emptyItem.service },
			// 	saleItems: [],
			// };

			// setItem(newItem);

			// eslint-disable-next-line
		}
		catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		setColumns([
			{
				title: "Descripción",
				field: "description",
				editable: 'never',
			},
			{
				title: "Cantidad ordenada",
				field: "quantity",
				type: 'numeric',
				editable: 'never',
				render: row => <span>{row.quantity} {row.measurementUnit}</span>
			},
			{
				title: "Observaciones",
				field: "observations",
				editable: 'never',
			},
			{
				title: "Cantidad en factura",
				field: 'quantityInvoiced',
				render: row => renderRowInputFactura(row)
			},
			{
				title: "Cantidad recibida",
				field: 'quantityReceived',
				render: row => renderRowInput(row)
			},
			{
				title: "Cantidad devolución",
				field: 'quantityReturned',
				render: row => renderRowInputDevolucion(row)
			},
			{
				title: "Cantidad sin costo",
				field: 'quantityFreeExtra',
				render: row => renderRowInputGratis(row)
			},
			{
				title: "Cantidad real",
				field: "quantity",
				type: 'numeric',
				editable: 'never',
				render: row => {
					let qty = 0;
					if (!isNaN(row.quantityReceived) && row.quantityReceived > 0) {
						qty += parseFloat(row.quantityReceived);
					}

					if (!isNaN(row.quantityFreeExtra) && row.quantityFreeExtra > 0) {
						qty += parseFloat(row.quantityFreeExtra);
					}

					if (!isNaN(row.quantityReturned) && row.quantityReturned > 0) {
						qty -= parseFloat(row.quantityReturned);
					}

					return (<span>{formatNumber(qty)}</span>);
				}
			},
			{
				title: "Comentario",
				field: 'comments',
				render: row => renderRowCommentInput(row)
			},
		]);
	}, [details]);

	useEffect(() => {
		loadItem();
	}, [id]);

	useEffect(() => {
	}, [supplierId]);

	const save = async () => {
		const model = details.map((i) => ({
			id: 0,
			...i,
			purchaseId: id,
			notes: i.comments,
			quantityReceived: parseFloat(i.quantityReceived || 0),
			quantityFreeExtra: parseFloat(i.quantityFreeExtra || 0),
			quantityReturned: parseFloat(i.quantityReturned || 0),
			QuantityInvoiced: parseFloat(i.quantityInvoiced || 0),
			totalReceived: parseFloat(i.quantityReceived || 0) + parseFloat(i.quantityFreeExtra || 0) - parseFloat(i.quantityReturned || 0),
		}));

		if (saving || !details || !details.length) {
			return;
		}

		if (details.filter(x => x.quantityReturned > 0 && !x.comments).length > 0) {
			props.addNotification({ message: 'Es necesario ingresar algun comentario en partidas con devoluciones', options: { variant: "error" } });
			setSaving(false);
			return;
		}

		setSaving(true);

		try {
			const response = await purchasesReceiptService.save(model, id, receiptType);

			if (!response || !response.data || !response.data.data || !response.data.data.result) {
				props.addNotification({ message: 'Error recibiendo mercancia', options: { variant: "error" } });
				setSaving(false);
				return;
			}
			else {
				props.addNotification({ message: 'Se han guardado los datos', options: { variant: "success" } });
				onSave();
			}
		} catch (e) {
			console.log(e);
		}

		setSaving(false);
	};

	// const getRowTotal = (detail, type) => {
	// 	if (type === 'R' && !detail.quantityReceived || isNaN(detail.quantityReceived)) {
	// 		detail.newTotal = 0;
	// 		return 0;
	// 	}

	// 	if (type === 'G' && !detail.quantityFreeExtra || isNaN(detail.quantityFreeExtra)) {
	// 		return 0;
	// 	}

	// 	if (type === 'D' && !detail.quantityReturned || isNaN(detail.quantityReturned)) {
	// 		return 0;
	// 	}


	// 	const newValue = parseFloat(type === 'R' ? detail.quantityReceived : type === 'G' ? detail.quantityFreeExtra : detail.quantityReturned);
	// 	const newTotal = (newValue * detail.price).toFixed(2);
	// 	detail.newTotal = newTotal;
	// 	detail.totalReceived = detail.quantityReceived + detail.quantityFreeExtra - detail.quantityReturned;
	// 	return newTotal;
	// };

	const renderRowInput = (row) => {
		const type = 'B';
		return (
			<TextField
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantityReceived}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.id == row.id);
					detail.quantityReceived = e.target.value;
					// getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	const renderRowInputFactura = (row) => {
		const type = 'R';
		return (
			<TextField
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantityInvoiced}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.id == row.id);
					detail.quantityInvoiced = e.target.value;
					// getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	const renderRowInputGratis = (row) => {
		const type = 'G';
		return (
			<TextField
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantityFreeExtra}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.id == row.id);
					detail.quantityFreeExtra = e.target.value;
					//getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	const renderRowInputDevolucion = (row) => {
		const type = 'D';
		return (
			<TextField
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantityReturned}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.id == row.id);
					detail.quantityReturned = e.target.value;
					//getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	const renderRowCommentInput = (row) => {
		return (
			<TextField
				type="text"
				value={row.comments}
				multiline
				fullWidth
				margin="dense"
				onChange={(e) => {
					const newDetails = [...details];
					const detail = newDetails.find(x => x.id == row.id);
					detail.comments = e.target.value;
					setDetails(newDetails);
				}}
			/>
		);
	};

	const previousFocus = (index, type) => {
		const prev = index - 1;

		if (prev < 0) {
			return;
		}

		if (refs[`input${prev}-${type}`] != null) {
			const input = refs[`input${prev}-${type}`];
			input.focus();
			input.setSelectionRange(0, input.value.length);
		}
		else {
			previousFocus(prev, type);
		}
	};

	const nextFocus = (index, type) => {
		let next = index + 1;

		if (next > details.length) {
			return;
		}

		if (refs[`input${next}-${type}`] != null) {
			const input = refs[`input${next}-${type}`];
			input.focus();
		}
		else {
			nextFocus(next, type);
		}
	};

	const renderForm = () => {
		return (
			<form className="contracts-form" key={id}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Empresa" value="AUPSA" InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Sucursal</InputLabel>
										<Select
											isDisabled
											key={branchId}
											isClearable
											value={branchId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setBranchId(selected.value);
												} else {
													setBranchId(null);
												}
											}}
											onClear={() => {
												setBranchId(null);
											}}
											options={branches}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										{/* <TextField fullWidth disabled label="Unidad" value={''} InputLabelProps={{ shrink: true }} /> */}
									</Grid>
									<Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
										<Grid
											direction="column"
											justify="center"
										>
											<InputLabel>Fecha</InputLabel>
											<DatePicker
												disabled
												format="DD/MM/YYYY"
												value={deliveryDate}
												onChange={date => setDeliveryDate(date)}
												autoOk
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Proveedor</InputLabel>
										<Select
											key={supplierId}
											isDisabled
											isClearable
											value={supplierId}
											onChange={(selected) => {
												if (selected) {
													setSupplierId(selected.value);
												} else {
													setSupplierId(null);
												}
											}}
											onClear={() => {
												setSupplierId(null);
											}}
											options={suppliers}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										{/* <TextField fullWidth disabled label="Mejor proveedor" value={''} InputLabelProps={{ shrink: true }} /> */}
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Status</InputLabel>
										<Select
											isDisabled
											isClearable
											value={statusId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setStatusId(selected.value);
												} else {
													setStatusId(null);
												}
											}}
											onClear={() => {
												setStatusId(null);
											}}
											options={statuses}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Tipo pedido</InputLabel>
										<Select
											isDisabled
											isClearable
											value={tipoPedidoId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setTipoPedidoId(selected.value);
												} else {
													setTipoPedidoId(null);
												}
											}}
											onClear={() => {
												setTipoPedidoId(null);
											}}
											options={tiposPedido}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Tipo de recibo</InputLabel>
										<Select
											isDisabled
											value={receiptType}
											onChange={(selected) => {
												if (selected && selected.value) {
													setReceiptType(selected.value);
												}
											}}
											options={receiptTypes}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.root} spacing={1}>
							<Grid item className={classes.item} xs={12}>
								<Table
									key={id}
									title="Detalle"
									isLoading={loading}
									columns={columns}
									items={details}
									options={{
										paging: false,
										search: false,
										selection: false,
									}}
								/>
							</Grid>
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{details && details.length > 0 &&
									<Button variant="outlined" color="primary" onClick={save}>
										Guardar recibo
									</Button>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
					&& <CircularProgress />
				}
				{renderForm()}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	if (hideLayout) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Orden de compra"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

PurchaseOrderForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

PurchaseOrderForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(withRouter(withStyles(styles)(PurchaseOrderForm)));
