export const formatNumber = (number, decimals = 2) =>
    `${new Intl.NumberFormat('es-MX', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(number || 0)}`;

export const formatNumberNoDecimals = (number) => `${new Intl.NumberFormat('es-MX').format(number || 0)}`;

export const formatCurrency = (number, decimals = 2) =>
    `${new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(number || 0)}`;

export const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;