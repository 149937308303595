import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import PurchaseOrderReceiveForm from "./Form";
// UI Components
import {
    withStyles,
    Grid,
    // CircularProgress,
    InputLabel,
    TextField,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';
import { DatePicker } from "@material-ui/pickers";

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import purchasesService from 'services/purchasesService';

//Assets
import styles from './styles';

class ReceivePurchaseOrders extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().startOf('week').toDate(),
        endDate: Moment.utc().endOf('week').toDate(),
        statusId: 1,
        loading: false
    };

    columns = [
        {
            title: "Folio",
            field: 'folio',
            render: (row) => (
                <Link
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ idToShow: row.purchaseId });
                    }}
                >
                    {row.folio}
                </Link>
            )
        },
        {
            title: "Sucursal",
            field: 'branchName'
        },
        {
            title: "Proveedor",
            field: 'supplierName'
        },
        {
            title: "Fecha de entrega",
            field: 'deliveryDate',
            render: row => {
                return (
                    <span>{Moment.utc(row.deliveryDate).format("DD/MM/YYYY")}</span>
                );
            }
        },
        {
            title: "Estado",
            field: 'status',
            render: (row) => <span>{this.getStatus(row)}</span>
        },
    ];

    statuses = [
        { value: 0, label: "Todos" },
        { value: 1, label: "Nueva" },
        { value: 2, label: "Pendiente de revisión" },
        { value: 3, label: "Confirmada y bloqueada para edicion" },
        { value: 4, label: "Recibida Parcialmente" },
        { value: 5, label: "Recibida" },
        // { value: 6, label: "Cancelada" },
    ]

    componentDidMount = () => {
        this.search();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            startDate,
            endDate,
            statusId,
        } = this.state;

        if (prevState.endDate != endDate && endDate < startDate)
            this.setState({ startDate: endDate }, this.search);
        else if (prevState.startDate != startDate && startDate > endDate)
            this.setState({ endDate: startDate }, this.search);
        else if (prevState.startDate != startDate || prevState.endDate != endDate || prevState.statusId != statusId)
            this.search();
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    search = async () => {
        const {
            startDate,
            endDate,
            statusId,
            folio,
        } = this.state;

        this.setState({ loading: true });

        const model = {
            status: statusId ? statusId : null,
            endDate,
            startDate,
            folio,
        };

        const response = await purchasesService.searchReceive(model);
        this.setState({ loading: false });

        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ requisitions: response.data.data });
        }
        else {
            this.props.addNotification({ message: "Error realizando busqueda", options: { variant: "error" } });
        }
    }

    getTitle = () => {
        return "Recepción de mercancia";
    }

    renderFilters = () => {
        const {
            startDate,
            endDate,
            folio,
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha inicial</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha final</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <InputLabel>Folio</InputLabel>
                    <TextField
                        type="text"
                        value={folio}
                        style={{ width: '100%' }}
                        onKeyPress={e => {
                            if (e.charCode === 13) {
                                this.search();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target != null && e.target.value != null) {
                                this.setState({ folio: e.target.value });
                            }
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            requisitions,
            selectedItems,
            copiyng,
            loading
        } = this.state;

        let items = requisitions;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            toolbar: false,
                            search: false
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            idToShow
        } = this.state;

        return (
            <Modal
                title="Recibir mercancia de orden de compra"
                isOpen={this.state.idToShow != null}
                onRequestClose={() => {
                    this.setState({ idToShow: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <PurchaseOrderReceiveForm
                    key={idToShow}
                    id={idToShow}
                    hideLayout
                    onClose={() => this.setState({ idToShow: null })}
                    onSave={() => {
                        this.setState({ idToShow: null });
                        this.search();
                    }}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(ReceivePurchaseOrders))))));