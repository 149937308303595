import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import AdjustInventoryForm from "./Form";
// UI Components
import {
    withStyles,
    Grid,
    Button,
    InputLabel,
    TextField,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import inventoriesService from 'services/inventoriesService';
import suppliersService from 'services/suppliersService';
import storagesService from 'services/storagesService';
import classificationsService from "services/classificationsService";

//Assets
import styles from './styles';
import { formatCurrency, formatNumber } from 'utils/numbers';

class AdjustInventory extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().startOf('week').toDate(),
        endDate: Moment.utc().endOf('week').toDate(),
        statusId: 1,
        loading: false,
        branches: [],
        storages: [],
        items: [],
        details: [],
        loadingDetails: false,
        showCodeSearch: false,
        itemsCodes: null
    };

    columns = [
        {
            title: "SAP Code",
            field: 'sapCode',
        },
        {
            title: "Artículo",
            field: 'itemDetail',
        },
        {
            title: "Cantidad",
            field: 'quantity',
            render: (row) => <span>{formatNumber(row.quantity)}</span>
        },
        {
            title: "U/M",
            field: 'measurementUnit'
        },
        {
            title: "Costo Prom.",
            field: 'averageCost',
            render: (row) => <span>{formatCurrency(row.averageCost)}</span>
        },
        {
            title: "Almacen",
            field: 'storageName'
        },
    ];

    actions = [
        {
            icon: 'edit',
            tooltip: 'Ajustar',
            onClick: (e, rowData) => this.setState({ idToShow: rowData.itemId })
        }
    ];


    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        this.getBranches();
        this.getClasifications();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            startDate,
            endDate,
            branchId,
            storageId,
            clasificationId,
            itemId,
            itemsCodes
        } = this.state;

        if (prevState.endDate != endDate && endDate < startDate)
            this.setState({ startDate: endDate }, this.search);
        else if (prevState.startDate != startDate && startDate > endDate)
            this.setState({ endDate: startDate }, this.search);
        else if (prevState.branchId != branchId || prevState.storageId != storageId || prevState.itemId != itemId 
            || prevState.clasificationId != clasificationId ) {
            this.search();
        }
        else if (prevState.itemsCodes != itemsCodes && !itemsCodes){
            this.search();
        }

        if (prevState.branchId != branchId) {
            this.getStorages();
            this.getItems();
        }

        if (prevState.storageId != storageId) {
            this.getItems();
        }
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    getBranches = async () => {
        const response = await suppliersService.getInternal();
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                branches: response.data.data.map((i) => ({
                    value: i.supplierId,
                    label: i.supplierName,
                }))
            });

            if (response.data && response.data.data && response.data.data.length > 0) {
                this.setState({ branchId: response.data.data[0].supplierId });
            }
        }
    }

    getStorages = async () => {
        const {
            branchId
        } = this.state;

        this.setState({ storages: [] });

        if (!branchId) {
            return;
        }

        const response = await storagesService.getById(branchId);
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                storages: response.data.data.map((i) => ({
                    value: i.storageId,
                    label: i.name,
                }))
            });
        }
    }

    getClasifications = async () => {
        this.setState({ clasifications: [] });

        const response = await classificationsService.getAll();
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                clasifications: response.data.data.map(i => ({
                    value: i.clasificationId, label: i.name, storageId: i.storageId
                }))
            });
        }
    }

    getItems = async () => {
        const {
            branchId,
        } = this.state;

        this.setState({ items: [] });

        if (!branchId) {
            return;
        }

        try {
            const response = await suppliersService.getSupplierItems(branchId);
            this.setState({ items: response && response.data ? response.data.data.map((i) => ({ value: i.itemId, label: i.item.detail || '', ...i })) : [] });
        } catch (e) {
            console.log(e);
        }
    };

    search = async () => {
        const {
            branchId,
            storageId,
            clasificationId,
            itemId,
            itemsCodes,
        } = this.state;

        this.setState({ loading: true });

        try {
            let response = null;
            if (itemId) {
                response = await inventoriesService.searchByItem(itemId);
            }
            else if (itemsCodes) {
                const lines = itemsCodes.split(/\r?\n/);
                const codes = lines.join(',');
                response = await inventoriesService.searchByCodes(codes,clasificationId, branchId);
            }
            else if (clasificationId) {
                response = await inventoriesService.searchByClasification(clasificationId, branchId);
            }
            else if (storageId) {
                response = await inventoriesService.searchByStorage(storageId);
            }
            else {
                response = await inventoriesService.searchByBranch(branchId);
            }

            if (response.ok && Array.isArray(response.data.data)) {
                this.setState({ requisitions: response.data.data });
            }
            else {
                this.props.addNotification({ message: "Error realizando busqueda", options: { variant: "error" } });
            }
        }
        catch (e) {
            console.log(e);
        }

        this.setState({ loading: false });

    }

    openBuscarCodigos = async () => {
        this.setState({ showCodeSearch: true });
    }

    filtrarCodigos = async () => {
        this.setState({ showCodeSearch: false });
        this.search();
    }

    limpiarCodigos = async () => {
        this.setState({ itemsCodes: '' });
        this.setState({ showCodeSearch: false });
    }

    getTitle = () => {
        return "Ajuste de Inventario";
    }

    renderFilters = () => {
        const {
            branchId,
            storageId,
            clasificationId,
            branches,
            storages,
            clasifications,
        } = this.state;

        let tmpClassifications = [...(clasifications || [])];

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={3} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Sucursal</InputLabel>
                        <Select
                            key={branches.length}
                            value={branchId}
                            onChange={(selected) => {
                                this.setState({ branchId: selected.value });
                            }}
                            options={branches}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Almacen</InputLabel>
                        <Select
                            isClearable
                            key={branchId}
                            value={storageId}
                            onChange={(selected) => {
                                this.setState({ storageId: selected.value });
                            }}
                            options={storages}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Clasificación</InputLabel>
                        <Select
                            isClearable
                            key={`B-${branchId}-S${storageId}`}
                            value={clasificationId}
                            onChange={(selected) => {
                                this.setState({ clasificationId: selected.value });
                            }}
                            options={tmpClassifications}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} lg={3} alignContent="center" className={classes.item}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button variant="outlined" style={{marginTop: 4}} color="primary" onClick={this.openBuscarCodigos}>
                            Buscar por Código
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            requisitions,
            selectedItems,
            copiyng,
            loading,
        } = this.state;

        let items = requisitions;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        actions={this.actions}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            toolbar: false,
                            search: false,
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderCodeSearchViewModal = () => {
        return (
            <Modal
                title="Búsqueda por Código"
                isOpen={this.state.showCodeSearch}
                onRequestClose={() => {
                    this.setState({ showCodeSearch: false });
                }}
                style={{ width: '30%', height: '70%' }}
            >
                <Grid container spacing={2} style={{ padding: 10 }}>
                    <Grid item xs={12}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField name="itemsCodes" fullWidth label="Códigos" multiline value={this.state.itemsCodes}
                            onChange={async (e) => this.setState({itemsCodes: e.target.value})} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', margin: 10}}>
                            <Button variant="outlined" color="primary" onClick={this.filtrarCodigos}>
                                OK
                            </Button>
                            <Button variant="outlined" color="primary" style={{marginLeft: 5}} onClick={this.limpiarCodigos}>
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderViewModal = () => {
        const {
            idToShow
        } = this.state;

        return (
            <Modal
                title="Ajuste de Inventario de Artículo"
                isOpen={this.state.idToShow != null}
                onRequestClose={() => {
                    this.setState({ idToShow: null });
                }}
                style={{ width: '95%', height: '60%' }}
            >
                <AdjustInventoryForm
                    key={idToShow}
                    id={idToShow}
                    hideLayout
                    onClose={() => this.setState({ idToShow: null })}
                    onSave={() => {
                        this.setState({ idToShow: null });
                        this.search();
                    }}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderCodeSearchViewModal()}
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(AdjustInventory))))));