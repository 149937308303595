import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';

// Material helpers
import {
	withStyles,
	Hidden,
	AppBar,
	Tabs,
	Tab,
	Typography,
	Box,
	Grid,
	TextField,
	Button,
	InputLabel,
} from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletContent,
	Table,
	Select,
} from 'components';
import { withSnackbar } from 'notistack';

import suppliersService from "services/suppliersService";

import {
	InfoRounded,
	ListOutlined,
	ReceiptOutlined,
} from '@material-ui/icons';

import styles from './styles';

import ModalComponent from 'components/Modal';
import SupplierItemForm from './../ItemForm';

import commonService from 'services/commonService';

import { WithNotifications } from 'hoc';

const tabProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

class EditForm extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		id: PropTypes.number,
		onSave: PropTypes.func
	};

	static defaultProps = {
		id: null,
		onSave: () => { },
	};

	emptyItem = {
		supplierId: null,
		address: "",
		alias: "",
		code: null,
		contactName: "",
		contactPhoneNumber: "",
		contactPhoneNumber2: "",
		createdBy: null,
		createdDate: null,
		creationDate: new Date(),
		creditDays: 0,
		email: null,
		importDate: new Date(),
		isActive: true,
		isExternal: true,
		modifiedBy: null,
		modifiedDate: null,
		rfc: "",
		sapCode: "",
		supplierDescription: "",
		supplierName: "",
		csf: {},
	};

	columns = [
		{
			title: "Artículo",
			field: 'itemDescription'
		},
		{
			title: "Descripción proveedor",
			field: 'itemExternalDescription'
		},
		{
			title: "Unidad de medida",
			field: 'measurementUnit'
		},
		{
			title: "Precio por unidad",
			field: "unitPrice",
		},
		{
			title: "Cantidad por empaque",
			field: "quantity",
		},
		{
			title: "Precio total",
			field: "totalPrice",
		},
	];

	state = {
		tab: 0,
		users: [],
		item: { ...this.emptyItem },
		items: [],
		loading: false,
		loadingItems: false,
		regimes: [],
		supplierTypes: [],
		isrTypes: [],
		ivaTypes: [],
		accountExecutives: [],
	};

	componentDidMount = () => {
		this.getData(this.props.id);
		this.getCatalogs();
	}

	getData = async (id) => {
		if (!id) {
			return;
		}

		try {
			const response = await suppliersService.getById(id);
			if (response.data.data) {
				const item = response.data.data;
				const responseCsf = await suppliersService.getCSF(id);
				item.csf = responseCsf.data.data;

				this.setState({ item });
				this.getItems(id);
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	getCatalogs = async () => {
		try {
			const regimesT = commonService.getTaxRegimes();
			const supplierTypesT = commonService.getSupplierTypes();
			const isrTypesT = commonService.getISRTypes();
			const ivaTypesT = commonService.getIVATypes();
			const accountExecutivesT = commonService.getAccountExecutives();

			let [
				regimesResponse,
				supplierTypesResponse,
				isrTypesResponse,
				ivaTypesResponse,
				accountExecutivesResponse,
			] = await Promise.all([
				regimesT,
				supplierTypesT,
				isrTypesT,
				ivaTypesT,
				accountExecutivesT
			]);

			if (regimesResponse.ok && regimesResponse.data.status != "Fail") {
				const items = regimesResponse.data.data.map(i => ({ value: i.taxRegimeId, label: i.description }));
				this.setState({ regimes: items });
			}

			if (supplierTypesResponse.ok && supplierTypesResponse.data.status != "Fail") {
				const items = supplierTypesResponse.data.data.map(i => ({ value: i.supplierTypeId, label: i.description }));
				this.setState({ supplierTypes: items });
			}

			if (isrTypesResponse.ok && isrTypesResponse.data.status != "Fail") {
				const items = isrTypesResponse.data.data.map(i => ({ value: i.isrTypeId, label: i.description }));
				this.setState({ isrTypes: items });
			}

			if (ivaTypesResponse.ok && ivaTypesResponse.data.status != "Fail") {
				const items = ivaTypesResponse.data.data.map(i => ({ value: i.ivaTypeId, label: i.description }));
				this.setState({ ivaTypes: items });
			}

			if (accountExecutivesResponse.ok && accountExecutivesResponse.data.status != "Fail") {
				const items = accountExecutivesResponse.data.data.map(i => ({ value: i.id, label: i.fullName }));
				this.setState({ accountExecutives: items });
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	getItems = async (id) => {
		if (!id) {
			return;
		}

		this.setState({ loadingItems: true });

		try {
			const response = await suppliersService.getItems(id);
			if (response.data.data) {
				this.setState({ items: response.data.data });
			}
		}
		catch (e) {
			console.log(e);
		}

		this.setState({ loadingItems: false });
	}

	save = async () => {
		const {
			item,
		} = this.state;

		const {
			onSave,
		} = this.props;

		const model = {
			...item,
			supplierId: item.supplierId ? item.supplierId : 0,
			csf: null,
		};

		this.setState({ loading: true });

		try {
			const response = await suppliersService.create(model);
			if (response && response.data && response.data.data && response.data.data.supplierId) {
				if (item.csf.taxRegimeId || item.csf.supplierTypeId || item.csf.isrTypeId || item.csf.ivaTypeId || item.csf.accountExecutiveId) {
					await suppliersService.saveCSF({ ...item.csf, supplierId: item.supplierId ? item.supplierId : 0 });
				}
				this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
				this.getData(response.data.data.supplierId);
				onSave();
			}
		} catch (e) {
			console.log(e);
		}

		this.setState({ loading: false });
	}

	deleteItem = async (id) => {
		const {
			item,
		} = this.state;

		const result = await Swal.fire({
			title: "Confirmación",
			text: "Desea eliminar el articulo ?",
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			confirmButtonText: "Si",
			cancelButtonText: "No"
		});

		console.log(result);

		if (result.value) {
			try {
				const response = await suppliersService.deleteItem(id);
				if (response && response.data && response.data.data && response.data.data.result) {
					this.props.addNotification({ message: 'Artículo eliminado', options: { variant: "success" } });
					this.getItems(item.supplierId);
				}
			} catch (e) {
				console.log(e);
			}
		}
	}

	renderGeneralInfo = () => {
		const {
			item,
			loading,
		} = this.state;

		return (
			<Grid container spacing={2} style={{ padding: 15 }}>
				{
					item.supplierId &&
					<Grid item xs={12}>
						<TextField
							label="Id"
							value={item.supplierId}
							disabled
						/>
					</Grid>
				}
				<Grid item xs={12} md={8}>
					<TextField
						autoFocus
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Nombre"
						value={item.supplierName}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, supplierName: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Alias"
						value={item.alias}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, alias: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Descripción"
						value={item.supplierDescription}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, supplierDescription: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="RFC"
						value={item.rfc}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, rfc: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Código SAP"
						value={item.sapCode}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, sapCode: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Días de crédito"
						value={item.creditDays}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, creditDays: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Teléfono"
						value={item.contactPhoneNumber}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, contactPhoneNumber: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Correo"
						value={item.email}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, email: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Dirección"
						value={item.address}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, address: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
					<Button
						variant="contained"
						color="primary"
						disabled={loading}
						onClick={this.save}
					>
						Guardar
					</Button>
				</Grid>
			</Grid>
		);
	}

	renderCSF = () => {
		const {
			item,
			regimes,
			supplierTypes,
			isrTypes,
			ivaTypes,
			accountExecutives,
		} = this.state;

		return (
			<Grid container spacing={2} style={{ padding: 15 }}>
				<Grid item xs={12} md={6}>
					<div>
						<InputLabel>
							Regimen fiscal
						</InputLabel>
						<Select
							value={item.csf.taxRegimeId}
							onChange={selected => {
								this.setState(ps => ({
									item: {
										...ps.item,
										csf: {
											...ps.item.csf,
											taxRegimeId: selected.value
										}
									}
								}));
							}}
							options={regimes}
							portal
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div>
						<InputLabel>
							Tipo
						</InputLabel>
						<Select
							value={item.csf.supplierTypeId}
							onChange={selected => {
								this.setState(ps => ({
									item: {
										...ps.item,
										csf: {
											...ps.item.csf,
											supplierTypeId: selected.value
										}
									}
								}));
							}}
							options={supplierTypes}
							portal
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div>
						<InputLabel>
							ISR
						</InputLabel>
						<Select
							value={item.csf.isrTypeId}
							onChange={selected => {
								this.setState(ps => ({
									item: {
										...ps.item,
										csf: {
											...ps.item.csf,
											isrTypeId: selected.value
										}
									}
								}));
							}}
							options={isrTypes}
							portal
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div>
						<InputLabel>
							IVA
						</InputLabel>
						<Select
							value={item.csf.ivaTypeId}
							onChange={selected => {
								this.setState(ps => ({
									item: {
										...ps.item,
										csf: {
											...ps.item.csf,
											ivaTypeId: selected.value
										}
									}
								}));
							}}
							options={ivaTypes}
							portal
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div>
						<InputLabel>
							Ejecutivo de cuenta
						</InputLabel>
						<Select
							value={item.csf.accountExecutiveId}
							onChange={selected => {
								this.setState(ps => ({
									item: {
										...ps.item,
										csf: {
											...ps.item.csf,
											accountExecutiveId: selected.value
										}
									}
								}));
							}}
							options={accountExecutives}
							portal
						/>
					</div>
				</Grid>
			</Grid>
		);
	}

	renderItems = () => {
		const {
			items,
			loadingItems,
		} = this.state;
		return (
			<Grid container spacing={3} style={{ padding: 10 }}>
				<Grid item xs={12}>
					<Table
						isLoading={loadingItems}
						columns={this.columns}
						items={items}
						options={{
							pageSize: 10,
							search: true
						}}
						actions={[
							{
								icon: 'delete',
								tooltip: "Eliminar",
								onClick: (e, rowData) => this.deleteItem(rowData.id),
							},
							{
								icon: 'edit',
								tooltip: "Editar",
								onClick: (e, rowData) => this.setState({ showModal: true, itemObj: rowData })
							},
							{
								icon: 'add',
								isFreeAction: true,
								tooltip: "Agregar",
								onClick: () => this.setState({ showModal: true, itemObj: null })
							}
						]}
					/>
				</Grid>
			</Grid>
		);
	}

	renderModal = () => {
		const {
			showModal,
			itemObj,
			item,
		} = this.state;

		return (
			<ModalComponent
				isOpen={showModal}
				title={itemObj ? "Modificar artículo" : "Agregar artículo"}
				onRequestClose={() => this.setState({ showModal: false, itemObj: null })}
				style={{ width: '85%' }}
			>
				<SupplierItemForm
					item={itemObj}
					supplierId={item.supplierId}
					onSave={() => {
						this.getItems(item.supplierId);
						this.setState({ showModal: false, itemObj: null });
					}}
				/>
			</ModalComponent>
		);
	}

	render() {
		const {
			classes,
		} = this.props;

		const {
			tab,
			item,
		} = this.state;

		return (
			<Portlet className={classes.root}>
				{this.renderModal()}
				<PortletContent noPadding>
					<AppBar position="static" color="default">
						<Tabs
							value={tab}
							onChange={(e, tab) => {
								this.setState({ tab });
							}}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								icon={<InfoRounded />}
								label={<Hidden xsDown>Datos generales</Hidden>}
								{...tabProps(0)}
							/>
							<Tab
								icon={<ReceiptOutlined />}
								label={<Hidden xsDown>Datos fiscales</Hidden>}
								{...tabProps(1)}
							/>
							<Tab
								icon={<ListOutlined />}
								label={<Hidden xsDown>Artículos</Hidden>}
								disabled={!item.supplierId}
								{...tabProps(2)}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={tab} index={0}>
						{this.renderGeneralInfo}
					</TabPanel>
					<TabPanel value={tab} index={1}>
						{this.renderCSF}
					</TabPanel>
					<TabPanel value={tab} index={2}>
						{this.renderItems}
					</TabPanel>
				</PortletContent>
			</Portlet>
		);
	}
}

export default WithNotifications(withStyles(styles)(withSnackbar(EditForm)));