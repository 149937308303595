import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { WithNotifications } from 'hoc';

// Material helpers
import {
	withStyles,
	Grid,
	TextField,
	InputLabel,
	Button,
} from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletContent,
	Select,
} from 'components';

import {
	PercentageFormatInput,
	CurrencyFormatInput,
	NumberFormatInput,
} from 'components';

import { withSnackbar } from 'notistack';

import suppliersService from "services/suppliersService";
import itemsService from "services/itemsService";
import storagesService from "services/storagesService";

import styles from './styles';

class ItemForm extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		item: PropTypes.object,
	};

	static defaultProps = {
		item: null,
		onSave: () => { },
	};

	emptyItem = {
		id: null,
		supplierId: null,
		itemExternalId: null,
		itemExternalDescription: "",
		quantity: null,
		unitPrice: null,
		totalPrice: null,
		itemId: null,
		itemDescription: "",
		measurementUnitId: null,
	};

	state = {
		item: {},
		items: [],
		branches: [],
		storages: [],
	};

	ums = [
		{ value: 1, label: "Kilogramos" },
		{ value: 2, label: "Litros" },
		{ value: 3, label: "Piezas" },
		{ value: 4, label: "Bidon" },
		{ value: 5, label: "Bolsa" },
		{ value: 6, label: "Caja" },
		{ value: 7, label: "Metro" },
		{ value: 8, label: "Paquete" },
		{ value: 9, label: "Servicio" },
	];

	componentDidMount = () => {
		const {
			item
		} = this.props;

		this.getCatalogs();
		this.setState({
			item: item
				? {
					...item,
					taxIvaPercentage: item.taxIvaPercentage ? (item.taxIvaPercentage * 100).toString() : "0"
				}
				: { ...this.emptyItem }
		});
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			item: {
				branchId,
			}
		} = this.state;

		if (branchId != prevState.item.branchId) {
			this.getStorages();
		}
	}

	getCatalogs = () => {
		this.getItems();
		this.getBranches();
	}

	getItems = async () => {
		try {
			const response = await itemsService.getItems();

			if (response.data && response.data.data) {
				this.setState({ items: [{ value: 0, label: "Sin relación", measurementUnitId: 1 }, ...response.data.data.map(i => ({ value: i.itemId, label: i.detail, measurementUnitId: i.measurementUnitId }))] });
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	getBranches = async () => {
		const response = await suppliersService.getInternal();
		if (response.ok && response.data.status != "Fail") {
			this.setState({
				branches: response.data.data.map((i) => ({
					value: i.supplierId,
					label: i.supplierName,
				}))
			});
		}
	}

	getStorages = async () => {
		const {
			item: {
				branchId
			}
		} = this.state;

		this.setState({ storages: [] });

		if (!branchId) {
			return;
		}

		const response = await storagesService.getById(branchId);
		if (response.ok && response.data.status != "Fail") {
			this.setState({
				storages: response.data.data.map((i) => ({
					value: i.storageId,
					label: i.name,
				}))
			});
		}
	}

	// getUms = async () => {
	// 	try {
	// 		const response = await suppliersService.getSupplierItems(512);

	// 		if (response.data && response.data.data) {
	// 			this.setState({ items: response.data.data.map(i => ({ ...i, value: i.item.itemId, label: i.item.detail })) });
	// 		}
	// 	}
	// 	catch (e) {
	// 		console.log(e);
	// 	}
	// }

	save = async () => {
		const {
			item,
		} = this.state;

		const {
			onSave,
			supplierId,
		} = this.props;

		const model = {
			...item,
			supplierId: supplierId,
			id: item.itemExternalId || 0,
			taxIvaPercentage: item.taxIvaPercentage ? parseFloat(item.taxIvaPercentage) / 100 : 0,
		};

		const value = model.taxIvaPercentage;

		if (value && (isNaN(value) || value < 0 || value > 100)) {
			this.props.addNotification({ message: 'Porcentaje de IVA inválido', options: { variant: "error" } });
			return;
		}

		this.setState({ loading: true });

		try {
			let response = null;

			if (model.id) {
				response = await suppliersService.updateItem(model);
			}
			else {
				response = await suppliersService.createItem(model);
			}

			if (response.data.data && response.data.data.id) {
				this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
				onSave();
			}
		} catch (e) {
			console.log(e);
		}

		this.setState({ loading: false });
	}

	updatePrices(priceValue) {
		this.setState(ps => ({ item: { ...ps.item, totalPrice: priceValue } }));
		this.setState(ps => ({ item: { ...ps.item, unitPrice: priceValue / ps.item.quantity } }));
	}

	render() {
		const {
			classes,
		} = this.props;

		const {
			item,
			items,
			loading,
			branches,
			storages,
		} = this.state;

		return (
			<Portlet className={classes.root}>
				<PortletContent noPadding>
					<Grid container spacing={2} style={{ padding: 15 }}>
						<Grid item xs={12}>
							<div
								className={classNames(classes.selectRoot)}
							>
								<InputLabel className={classes.inputLabel}>
									Artículo de venta
								</InputLabel>
								<Select
									isDisabled={item.itemExternalId != null && item.itemExternalId > 0}
									disabled={item.itemExternalId != null && item.itemExternalId > 0}
									value={item.itemId}
									onChange={selected => {
										this.setState(ps => ({
											item: {
												...ps.item,
												itemId: selected.value,
												quantity: 1,
												itemExternalDescription: selected.value > 0 ? selected.label : "",
												unitPrice: "",
												measurementUnitId: selected.measurementUnitId
											}
										}));
									}}
									options={items}
									portal
								/>
							</div>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label="Descripción"
								value={item.itemExternalDescription}
								onChange={(e) => this.setState(ps => ({ item: { ...ps.item, itemExternalDescription: e.target.value } }))}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label="Cantidad por empaque"
								InputProps={{
									inputComponent: NumberFormatInput,
								}}
								value={item.quantity}
								onChange={(e) => this.setState(ps => ({ item: { ...ps.item, quantity: e.target.value } }))}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<div
								className={classNames(classes.selectRoot)}
							>
								<InputLabel className={classes.inputLabel}>
									Unidad de medida
								</InputLabel>
								<Select
									key={item.itemId}
									isDisabled={item.itemId > 0}
									disabled={item != null && item.value > 0}
									value={item.measurementUnitId}
									onChange={selected => this.setState(ps => ({ item: { ...ps.item, measurementUnitId: selected.value } }))}
									options={this.ums}
								/>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label="Precio por empaque"
								InputProps={{
									inputComponent: CurrencyFormatInput,
								}}
								value={item.totalPrice}
								onChange={(e) => this.updatePrices(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="IVA"
								value={item.taxIvaPercentage}
								onChange={(e) => {
									const value = e.target.value;

									if (!value || (!isNaN(value) && value >= 0 && value < 100)) {
										this.setState(ps => ({ item: { ...ps.item, taxIvaPercentage: e.target.value } }));
									}
									else {
										this.props.addNotification({ message: 'Porcentaje inválido', options: { variant: "warning" } });
										//e.preventDefault();
									}
								}}
								name="taxIvaPercentage"
								InputProps={{
									inputComponent: PercentageFormatInput,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label="Precio Unitario"
								InputProps={{
									inputComponent: CurrencyFormatInput,
								}}
								disabled
								value={item.unitPrice}
							/>
						</Grid>
						{item && !item.itemId &&
							<Grid item xs={12} md={6}>
								<div
									className={classNames(classes.selectRoot)}
								>
									<InputLabel className={classes.inputLabel}>
										Sucursal
									</InputLabel>
									<Select
										value={item.branchId}
										onChange={selected => this.setState(ps => ({ item: { ...ps.item, branchId: selected.value } }))}
										options={branches}
									/>
								</div>
							</Grid>
						}
						{item && !item.itemId &&
							<Grid item xs={12} md={6}>
								<div
									className={classNames(classes.selectRoot)}
								>
									<InputLabel className={classes.inputLabel}>
										Almacen
									</InputLabel>
									<Select
										key={item.branchId || 0}
										value={item.storageId}
										onChange={selected => this.setState(ps => ({ item: { ...ps.item, storageId: selected.value } }))}
										options={storages}
									/>
								</div>
							</Grid>
						}
						<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
							<Button
								variant="contained"
								color="primary"
								disabled={loading}
								onClick={this.save}
							>
								Guardar
							</Button>
						</Grid>
					</Grid>
				</PortletContent>
			</Portlet>
		);
	}
}

export default WithNotifications(withStyles(styles)(withSnackbar(ItemForm)));