import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import { formatNumber } from 'utils/numbers';

import {
	withStyles,
	Grid,
	Typography,
} from '@material-ui/core';


import {
	Table,
} from 'components';


//Services
import { WithNotifications } from 'hoc';
import inventoriesService from 'services/inventoriesService';

//Assets
import styles from './styles';
import { formatDate } from 'utils/dates';

function TrackingInventoryItem(props) {
	const {
		classes,
		itemId,
		storageId,
	} = props;

	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [columns] = useState([
		// {
		// 	title: "Id",
		// 	field: 'inventoryMovementId',
		// },
		{
			title: "Movimiento",
			field: 'movement',
		},
		{
			title: "Descripción",
			field: 'movementDescription',
		},
		{
			title: "Cantidad anterior",
			field: 'quantityInitial',
			render: (r) => formatNumber(r.quantityInitial),
		},
		{
			title: "Cantidad",
			field: 'quantity',
			render: (r) => formatNumber(r.quantity),
		},
		{
			title: "Nueva cantidad",
			field: 'quantityFinal',
			render: (r) => <b>{formatNumber(r.quantityFinal)}</b>,
		},
		{
			title: "Costo unitario",
			field: 'unitPriceFinal',
			render: (r) => formatNumber(r.unitPriceFinal),
		},
		{
			title: "Costo prom. ant.",
			field: 'avgCostInitial',
			render: (r) => formatNumber(r.avgCostInitial),
		},
		{
			title: "Costo prom. actual",
			field: 'avgCostFinal',
			render: (r) => formatNumber(r.avgCostFinal),
		},
		{
			title: "Fecha",
			field: 'movementDate',
			render: (r) => formatDate(r.movementDate),
		},
		// {
		// 	title: "Usuario",
		// 	field: 'userName'
		// },
	]);

	const getItems = async () => {
		setLoading(true);

		if (!itemId || !storageId) {
			setLoading(false);
			return;
		}

		try {
			const response = await inventoriesService.getItemTracking(itemId, storageId);
			setItems(response.data.data);
		} catch (e) {
			console.log(e);
		}

		setLoading(false);
	};

	useEffect(() => {
		getItems();
	}, [itemId, storageId]);

	return (
		<Grid container className={classes.root} spacing={2} style={{ padding: 20 }}>
			<Grid item xs={12}>
				<Typography variant="h5">
					Movimientos
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Table
					columns={columns}
					items={items}
					options={{
						toolbar: false,
						search: false,
						paging: false,
						maxBodyHeight: 300
					}}
					isLoading={loading}
				/>
			</Grid>
		</Grid>
	);
}

TrackingInventoryItem.propTypes = {
	classes: PropTypes.object.isRequired,
	itemId: PropTypes.number,
	storageId: PropTypes.number,
};

TrackingInventoryItem.defaultProps = {
};

export default WithNotifications(withRouter(withStyles(styles)(TrackingInventoryItem)));
