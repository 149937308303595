import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../routes/PrivateRoute';

// Views
import Dashboard from '../views/Dashboard';
import Account from '../views/Account';
import ChangePassword from '../views/ChangePassword';
import Settings from '../views/Settings';
import SignUp from '../views/SignUp';
import SignIn from '../views/SignIn';
import UnderDevelopment from '../views/UnderDevelopment';
import RequisitionsToReceive from '../views/RequisitionsToReceive';
import PendingRequisitions from '../views/PendingRequisitions';
import ConfirmRequisition from '../views/ConfirmRequisition';
import PendingSupplyItems from '../views/PendingSupplyItems';
import DownloadRequisitionsLayout from '../views/DownloadRequisitionsLayout';
import RoutesCheckList from '../views/RoutesCheckList';
import DriverReturns from '../views/DriverReturns';
import LunchRoomsInfo from "../views/LunchRoomsInfo";
import SearchRequisitions from "../views/SearchRequisitions";
import RoutesView from "../views/Routes";
import Vehicles from "../views/Vehicles";
import NewsForm from "../views/News/Form";
import NewsList from "../views/News/List";
import Branches from "../views/Branches";
import Items from "../views/Items";
import ExternalSuppliers from "../views/ExternalSuppliers";
import Deliveries from "../views/Deliveries";
import SearchDeliveries from "../views/SearchDeliveries";
import ClientsContractsForm from "../views/ClientsContracts/Form";
import DownloadDetailedRQReport from "../views/DownloadDetailedRQReport";
import DownloadConcentratedRQReport from "../views/DownloadConcentratedRQReport";
import PurchaseOrder from "../views/PurchaseOrder/Form";
import CreateTransfer from "../views/Transfer/Form";
import ReceiveTransfer from "../views/Transfer/Receive";
import SearchPurchaseOrders from "../views/PurchaseOrder/Search";
import ReceivePurchaseOrders from "../views/PurchaseOrder/Receive";
import SearchInventories from "../views/Inventories/Search";
import AdjustInventory from "../views/Inventories/Adjustment";
import RQRequestedToTransform from "../views/Transformations/Requested";
import SearchTransformation from "../views/Transformations/Search";
import NotFound from '../views/NotFound';
import WithAuth from '../hoc/WithAuth';
import { withSnackbar } from 'notistack';
import { WithNotifications, WithPushNotifications } from 'hoc';

const EnhacedSignIn = WithAuth(SignIn);
const EnhacedPrivateRoute = WithAuth(PrivateRoute);

class Routes extends Component {
    componentWillMount = () => {
        if (!localStorage.appUser) {
            this.props.setSession(null);
        }

        try {
            const user = JSON.parse(localStorage.appUser);
            this.props.setSession(user);
        } catch (e) {
            this.props.setSession(null);
        }
    };

    componentDidMount = () => { };

    componentDidUpdate = prevProps => {
        // const { pushNotification } = this.props;

        if (prevProps.notification == null && this.props.notification != null) {
            this.props.enqueueSnackbar(
                this.props.notification.message,
                this.props.notification.options
            );
            this.props.clearNotification();
        }

        //Web push notifications subscribe
        if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
            this.props.getPermissions();
        }

        //Web push notifications unsubscribed
        // if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
        //     pushNotification && pushNotification.unsubscribePushNotification();
        // }
    };

    render() {
        return (
            <Switch>
                <Redirect exact from="/" to="/dashboard" />
                <EnhacedPrivateRoute component={Dashboard} path="/dashboard" />
                <EnhacedPrivateRoute component={Account} path="/account" />
                <EnhacedPrivateRoute component={ChangePassword} path="/change-password" />
                <EnhacedPrivateRoute component={ChangePassword} path="/profile" />
                <EnhacedPrivateRoute component={Settings} path="/settings" />
                <EnhacedPrivateRoute component={RequisitionsToReceive} path="/receive-requisitions" />
                <EnhacedPrivateRoute exact component={PendingRequisitions} path="/confirm-requisitions" />
                <EnhacedPrivateRoute component={ConfirmRequisition} path="/confirm-requisitions/:id/details" />
                <EnhacedPrivateRoute component={Items} path="/items" />
                <EnhacedPrivateRoute component={PendingSupplyItems} path="/supply-items" />
                <EnhacedPrivateRoute component={DownloadRequisitionsLayout} path="/download-layout" />
                <EnhacedPrivateRoute component={RoutesCheckList} path="/route-check-list" />
                <EnhacedPrivateRoute component={DriverReturns} path="/driver-returns" />
                <EnhacedPrivateRoute component={Deliveries} path="/consigment-notes" />
                <EnhacedPrivateRoute component={SearchDeliveries} path="/search-deliveries" />
                <EnhacedPrivateRoute component={LunchRoomsInfo} path="/lunchrooms-info" />
                <EnhacedPrivateRoute component={SearchRequisitions} path="/search-requisitions" />
                <EnhacedPrivateRoute component={RoutesView} path="/routes" />
                <EnhacedPrivateRoute component={Vehicles} path="/vehicles" />
                <EnhacedPrivateRoute component={Items} path="/items" />
                <EnhacedPrivateRoute component={Branches} path="/branches" />
                <EnhacedPrivateRoute component={ExternalSuppliers} path="/suppliers" />
                <EnhacedPrivateRoute component={DownloadDetailedRQReport} path="/detailed-rq-report" />
                <EnhacedPrivateRoute component={DownloadConcentratedRQReport} path="/concentrated-rq-report" />
                <EnhacedPrivateRoute component={NewsForm} path="/news/:id?" />
                <EnhacedPrivateRoute component={NewsList} path="/news-list" />
                <EnhacedPrivateRoute component={ClientsContractsForm} path="/clients-contracts" />
                <EnhacedPrivateRoute component={PurchaseOrder} path="/purchase-orders/create" />
                <EnhacedPrivateRoute component={SearchPurchaseOrders} path="/purchase-orders/search" />
                <EnhacedPrivateRoute component={ReceivePurchaseOrders} path="/purchase-orders/receipt" />
                <EnhacedPrivateRoute component={SearchInventories} path="/inventories/search" />
                <EnhacedPrivateRoute component={CreateTransfer} path="/inventories/create-transfer" />
                <EnhacedPrivateRoute component={ReceiveTransfer} path="/inventories/receive-transfer" />
                <EnhacedPrivateRoute component={AdjustInventory} path="/inventories/adjustment" />
                <EnhacedPrivateRoute component={RQRequestedToTransform} path="/transformations/requested" />
                <EnhacedPrivateRoute component={SearchTransformation} path="/transformations/search" />
                <Route component={SignUp} path="/sign-up" />
                <Route component={EnhacedSignIn} path="/sign-in" />
                <Route component={UnderDevelopment} path="/under-development" />
                <Route component={NotFound} path="/not-found" />
                <Redirect to="/not-found" />
            </Switch>
        );
    }
}

export default WithNotifications(
    withSnackbar(WithAuth(WithPushNotifications(Routes)))
);
