import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import { DatePicker } from "@material-ui/pickers";


import {
	withStyles,
	Grid,
	CircularProgress,
	Button,
	InputLabel,
	TextField,
	CardContent,
	Card,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import {
	Portlet,
	PortletContent,
	Select
} from 'components';


//Services
import { WithNotifications } from 'hoc';
import inventoriesService from 'services/inventoriesService';
//Assets
import styles from './styles';
import { isArray } from 'validate.js';

function AdjustInventoryForm(props) {
	const {
		classes,
		title,
		hideLayout,
		onSave,
	} = props;

	const [loading] = useState(false);
	const [adjustMotivations, setAdjustMotivations] = useState([]);
	const [adjustMotivationId, setAdjustMotivationId] = useState(null);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const [id] = useState(props.id);
	const [saving, setSaving] = useState(false);
	const [item, setItem] = useState({});

	const init = async () => {
		inventoriesService.getAdjustmentMotivations().then(response => {
			setAdjustMotivations(response && response.data ? response.data.data.map((i) => ({ value: i.id, label: i.description })) : []);
		});
	};

	const loadItem = async () => {
		if (!id) {
			return;
		}
		
		try {
			inventoriesService.searchByItem(id).then(response => {
				if(!response || !response.data || !response.data.data || !isArray(response.data.data)) {
					return;
				}

				setItem(response.data.data[0]);
			});
		}
		catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		init();
	}, []);


	useEffect(() => {
		loadItem();
	}, [id]);

	useEffect(() => {}, [item]);

	const renderForm = () => {
		return (
			<form className="contracts-form" key={id}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
										<Grid
											direction="column"
											justify="center"
										>
											<InputLabel>Fecha</InputLabel>
											<DatePicker
												disabled
												format="DD/MM/YYYY"
												value={deliveryDate}
												onChange={date => setDeliveryDate(date)}
												autoOk
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Artículo</InputLabel>
										<TextField fullWidth disabled value={item.itemDetail} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Almacen</InputLabel>
										<TextField fullWidth disabled value={item.storageName} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth label="Folio" value={item.adjustFolio} 
										onChange={ (e) => { setItem({...item, adjustFolio: e.target.value}); } }/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Motivo</InputLabel>
										<Select
											key={adjustMotivationId}
											isClearable
											value={adjustMotivationId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setAdjustMotivationId(selected.value);
												} else {
													setAdjustMotivationId(null);
												}
											}}
											onClear={() => {
												setAdjustMotivationId(null);
											}}
											options={adjustMotivations}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Inventario Actual</InputLabel>
										<TextField disabled fullWidth value={item.quantity} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth label="Ajuste" value={item.adjustment} onChange={changeAdjustment} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Dif. Ajuste</InputLabel>
										<TextField disabled fullWidth value={item.diffAdjustment} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={12} lg={12}>
										<TextField fullWidth label="Comentario" multiline="true" value={item.adjustComments} 
										onChange={ (e) => { setItem({...item, adjustComments: e.target.value}); } }/>
									</Grid>
									<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<Button variant="outlined" color="primary" onClick={save}>
											Guardar Ajuste
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		);
	};

	const changeAdjustment = async (e) => {
		if(e.target.value == '' || e.target.value == null){
			setItem({...item,adjustment: '',diffAdjustment: ''});
			return;
		}


		if(isNaN(e.target.value)) {
			return;
		}

		const adjustment = +e.target.value;
		setItem({...item,adjustment: adjustment,diffAdjustment: item.quantity + adjustment});
	};

	const save = async () => {
		if (saving) {
			return;
		}
		setSaving(true);

		item.adjustMotivationId = adjustMotivationId;
		if(!item.adjustMotivationId) {
			props.addNotification({ message: 'Se debe seleccionar un motivo', options: { variant: "warning" } });
			setSaving(false);
			return;
		}

		if(!item.adjustment) {
			props.addNotification({ message: 'Se debe especificar un ajuste', options: { variant: "warning" } });
			setSaving(false);
			return;
		}
		else if(isNaN(item.adjustment)) {
			props.addNotification({ message: 'Ajuste debe ser numérico', options: { variant: "warning" } });
			setSaving(false);
			return;
		}

		if(!item.adjustFolio) {
			props.addNotification({ message: 'Se debe seleccionar un folio', options: { variant: "warning" } });
			setSaving(false);
			return;
		}

		try {
			const response = await inventoriesService.saveAdjustment(item);

			if (!response || !response.data || !response.data.data || !response.data.data.result) {
				props.addNotification({ message: 'Error guardando ajuste de Inventario', options: { variant: "error" } });
				setSaving(false);
				return;
			}
			else {
				props.addNotification({ message: 'El ajuste de inventario se ha guardado exitósamente', options: { variant: "success" } });
				onSave();
			}
		} catch (e) {
			console.log(e);
			setSaving(false);
		}
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
					&& <CircularProgress />
				}
				{renderForm()}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	if (hideLayout) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Ajuste Inventario"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

AdjustInventoryForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

AdjustInventoryForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(withRouter(withStyles(styles)(AdjustInventoryForm)));
