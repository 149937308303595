import Api from "./Api";

class CityService extends Api {
	constructor(controller) {
		super(controller);
	}

	save = model => {
		return this.API.post(`${this.controller}`, model);
	};

	getItems = (storageId) => {
		return this.API.get(`${this.controller}/ItemsByStorage?StorageId=${storageId}`);
	};

	getBaseItems = (storageId) => {
		return this.API.get(`${this.controller}/BaseItemsByStorage?StorageId=${storageId}`);
	};
}

let service = new CityService("storages");

export default service;