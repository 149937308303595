import API from "./Api";

class CommonService extends API {
    constructor(controller){
        super(controller);
    }

    getTaxRegimes = () => {
        return this.API.get(`tax-regime`);
    }

    getSupplierTypes = () => {
        return this.API.get(`supplier-type`);
    }

    getISRTypes = () => {
        return this.API.get(`isr-type`);
    }

    getIVATypes = () => {
        return this.API.get(`iva-type`);
    }

    getAccountExecutives = () => {
        return this.API.get(`users/cxp`);
    }

    getUsosCfdi = () => {
        return this.API.get(`cfdi-uses`);
    }

    getPaymentTypes = () => {
        return this.API.get(`payment-method`);
    }

    getTenderTypes = () => {
        return this.API.get(`tender-type`);
    }
}

let service = new CommonService("common");

export default service;