import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    InputLabel,
    Hidden,
    AppBar,
    Tabs,
    Tab,
    Button,
    TextField
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select, TabPanel, tabProps, ButtonToolTip } from 'components';
import { DatePicker } from "@material-ui/pickers";
import {
    InfoRounded,
    ArrowBack
} from '@material-ui/icons';

//Services
import { WithTicket, WithSession } from "hoc";
import productsService from "services/productsService";
import routesService from "services/routesService";
import lunchRoomsService from "services/lunchRoomsService";
// import requisitionDetailsService from "services/requisitionDetailsService";
import driversService from "services/driversService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';
import { SelectAsync } from 'components';

class DriverReturns extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        routes: [],
        lunchRooms: [],
        date: Moment().startOf('day').toDate(),
        routeId: null,
        lunchRoom: null,
        // date: Moment('18/04/2020', "DD/MM/YYYY").startOf('day').toDate(),
        // routeId: 6,
        // lunchRoom: { lunchRoomName: "FAMA", lunchRoomId: 8 },
        tab: 0,
        plasticBoxes: "",
        details: []
    };

    columns = [
        {
            title: "Unidad",
            field: 'lunchRoomName'
        },
        {
            title: "Estado",
            field: 'returnStatus'
        },
        {
            title: " ",
            field: ' ',
            render: (row) => {
                if (row.returnStatus != "Por registrar devolución")
                    return null;

                return (
                    <Button color="primary" onClick={() => this.setState({ lunchRoom: row })}>Registrar</Button>
                );
            }
        }
    ];

    detailColumns = [
        {
            title: "Almacen",
            field: 'storageName'
        },
        {
            title: "Articulo",
            field: 'itemPresentationDescription'
        },
        {
            title: "Cantidad",
            field: 'quantity',
            type: 'numeric'
        },
        {
            title: "U/M",
            field: 'measurementUnitName'
        }
    ];

    componentDidMount = () => {
        this.getData();
        this.search();

        if (this.state.lunchRoom)
            this.getLunchRooms();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            date,
            routeId,
        } = this.state;

        if (prevState.date != date || prevState.routeId != routeId)
            this.search();

        if (!prevState.lunchRoom && this.state.lunchRoom)
            this.getLunchRooms();
    }

    getData = async () => {
        const routesResponse = await routesService.getAll();
        if (routesResponse.ok && routesResponse.data.status != "Fail") {
            const routes = routesResponse.data.data.map(i => ({ value: i.routeId, label: i.name }));
            this.setState({ routes });
        }
    }

    getLunchRooms = async () => {
        // const {
        //     lunchRoom,
        //     date
        // } = this.state;

        // const response = await requisitionDetailsService.getRouteLunchRoomDetails(lunchRoom.lunchRoomId, date);

        // if (!response.ok) {
        //     this.props.addNotification({ message: response.data.data.error, options: { variant: "error" } });
        //     return;
        // }

        // this.setState({
        //     details: response.data.data.map((i, index) => ({ ...i, index }))
        // });
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    search = async () => {
        const {
            date,
            routeId
        } = this.state;

        this.setState({ loading: true });

        const response = await lunchRoomsService.search(routeId, date);
        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ lunchRooms: response.data.data });
        }

        this.setState({ loading: false });
    }

    getTitle = () => {

        return "Devoluciones de Chofer";
    }

    saveReturn = async () => {
        const {
            plasticBoxes,
            details,
            lunchRoom,
            date
        } = this.state;

        if (plasticBoxes == "" || isNaN(plasticBoxes)) {
            this.props.addNotification({ message: "Número de cajas plasticas requerido, al menos 0", options: { variant: "error" } });
            return;
        }

        if (this.saving)
            return;

        this.saving = true;
        this.setState({ saving: true });

        const model = {
            lunchRoomId: lunchRoom.lunchRoomId,
            plasticBoxes,
            date,
            quantities: details.map(i => ({
                itemPresentationId: i.itemPresentationId,
                quantity: i.quantity
            }))
        };

        const response = await driversService.postReturn(model).catch(() => {
            this.saving = false;
            this.setState({ saving: false });
        });

        this.saving = false;
        this.setState({ saving: false });

        if (!response.ok || !response.data.data) {
            this.props.addNotification({ message: "Error validando unidad", options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: "Devolucion registrada", options: { variant: "success" } });
        const lunchRooms = this.state.lunchRooms;
        const currentLunchRoom = lunchRooms.find(x => x.lunchRoomId == this.state.lunchRoom.lunchRoomId);
        currentLunchRoom.returnStatus = "Devolucion registrada";
        this.setState({ lunchRoom: null, plasticBoxes: "", details: [], lunchRooms });
    }

    renderFilters = () => {
        const {
            date,
            routeId,
            routes
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={date}
                            onChange={date => this.setState({ date })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Ruta</InputLabel>
                        <Select
                            value={routeId}
                            onChange={(selected) => {
                                this.setState({ routeId: selected.value });
                            }}
                            options={routes}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    openAddItemModal = () => {

        this.setState({ showItemsModal: true });
    }

    deleteItem = (row) => {
        let details = this.state.details;
        details = details.filter(x => x.itemPresentationId != row.itemPresentationId);
        this.setState({ details });
    }

    addItem = () => {
        const {
            item,
            quantity
        } = this.state;

        let details = this.state.details;
        details.push({
            storageName: item.storageName,
            itemPresentationDescription: item.label,
            quantity: quantity,
            itemPresentationId: item.value,
            measurementUnitName: item.measurementUnitName
        });

        this.setState({ details, showItemsModal: false, quantity: "", itemId: null, item: null });
    }

    renderDetailsTable = () => {
        const {
            details
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.detailColumns}
                        items={details}
                        key={details.length}
                        emptyValue={<div><span>Sin articulos agregados </span><Button color="primary" onClick={this.openAddItemModal}>Agregar</Button></div>}
                        options={{
                            paging: false,
                            search: false
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: "Agregar",
                                isFreeAction: true,
                                onClick: this.openAddItemModal
                            },
                            {
                                icon: 'delete',
                                tooltip: "Eliminar",
                                isFreeAction: false,
                                onClick: (e, rowData) => this.deleteItem(rowData)
                            }
                        ]}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderTable = () => {
        const {
            lunchRooms,
            selectedItems,
            copiyng,
            loading
        } = this.state;

        let items = lunchRooms;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            // selection: copiyng ? false : true
                        }}
                        // actions={copiyng ? [] : [
                        //     {
                        //         icon: 'content_copy',
                        //         tooltip: 'Copiar al porta papeles',
                        //         onClick: this.copyRows
                        //     }
                        // ]}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderGeneralInfo = () => {
        const {
            classes
        } = this.props;

        const {
            lunchRoom,
            isLoading,
            date,
            plasticBoxes
        } = this.state;

        return (
            <React.Fragment>

                <Grid container justify="center" className={classes.root} spacing={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                value={lunchRoom.lunchRoomName}
                                disabled
                                label={"Unidad"}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                disabled
                                value={Moment(date).format("DD/MM/YYYY")}
                                label={"Fecha"}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                value={plasticBoxes}
                                name="displayName"
                                label={"Cantidad de cajas plasticas"}
                                type="number"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => {
                                    this.setState({ plasticBoxes: e.target.value });
                                }}
                            />
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            md={6}
                            lg={2}
                            justify="center"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                onClick={this.saveReturn}
                            >
                                Guardar
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {this.renderDetailsTable()}
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    renderConfirm = () => {
        const { classes } = this.props;
        const { tab } = this.state;

        return (
            <div>
                <ButtonToolTip
                    className={classes.backButton}
                    onClick={() => {
                        this.setState({ lunchRoom: null, plasticBoxes: "", details: [] });
                    }}
                    title="Regresar"
                >
                    <ArrowBack />
                </ButtonToolTip>
                <AppBar
                    position="static"
                    color="default"
                >
                    <Tabs
                        value={tab}
                        onChange={(e, tab) => {
                            this.setState({ tab });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            icon={<InfoRounded />}
                            label={<Hidden xsDown>Devoluciones de Unidad</Hidden>}
                            {...tabProps(0)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                    {
                        this.renderGeneralInfo()
                    }
                </TabPanel>
            </div>
        );
    }

    renderAddItemModal = () => {
        const {
            itemId,
            quantity
        } = this.state;

        return (
            <Modal
                title="Agregar artículo"
                isOpen={this.state.showItemsModal}
                onRequestClose={() => {
                    this.setState({ showItemsModal: false });
                }}
                style={{ width: '350px', height: '343px' }}
            >
                <Grid container style={{ padding: 10, height: 300 }} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <InputLabel>Articulo</InputLabel>
                        <SelectAsync
                            value={itemId}
                            onChange={(selected) => this.setState({ itemId: selected.value, item: selected })}
                            cacheOptions
                            loadOptions={async value => {
                                if (!value) return [];
                                const res = await productsService.search(value);

                                const data = res.data && res.data.data && Array.isArray(res.data.data)
                                    ? res.data.data
                                    : [];

                                return data.map(i => ({
                                    value: i.itemPresentationId,
                                    label: i.itemDetail == i.itemPresentationDescription
                                        ? i.itemDetail
                                        : i.itemDetail + " ** " + i.itemPresentationDescription,
                                    storageName: i.storageName,
                                    measurementUnitName: i.measurementUnitName
                                }));
                            }}
                            onInputChange={newValue => {
                                this.setState({ articuloSearch: newValue });
                                return newValue;
                            }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Cantidad</InputLabel>
                        <TextField
                            value={quantity}
                            onChange={(e) => this.setState({ quantity: e.target.value })}
                            fullWidth
                        />
                    </Grid>
                    <Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.addItem}
                        >
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
            lunchRoom
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : (
                                            lunchRoom
                                                ? this.renderConfirm()
                                                : this.renderTable()
                                        )
                                }
                            </PortletContent>
                        </Portlet>
                        {this.renderAddItemModal()}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(WithNotifications(WithTicket(withStyles(styles)(DriverReturns))))));