import Api from "./Api";

class SuppliersService extends Api {
	constructor(controller) {
		super(controller);
	}

	save = model => {
		return this.API.post(`${this.controller}`, model);
	};

	getInternal = () => {
		return this.API.get(`branches`);
	};

	getItems = (id) => {
		return this.API.get(`items-external/${id}/items`);
	};

	getStorageItems = (branchId) => {
		return this.API.get(`items/GetItemStorageBySupplier/${branchId}`);
	};

	addStorageItem = (item) => {
		return this.API.post(`items/AddItemStorage`, item);
	};

	deleteStorageItem = (item) => {
		return this.API.post(`items/DeleteItemStorage`, item);
	};

	getCSF = (id) => {
		return this.API.get(`suppliers-csf?SupplierId=${id}`);
	};

	saveCSF = (model) => {
		return this.API.post(`suppliers-csf`, model);
	};

	createItem = (model) => {
		return this.API.post(`items-external/item/create`, model);
	};

	updateItem = (model) => {
		return this.API.post(`items-external/item/update`, model);
	};

	deleteItem = (id) => {
		return this.API.delete(`items-external/item/${id}`);
	};

	getExternal = () => {
		return this.API.get(`items-external`);
	};

	getSupplierItems = (supplierId) => {
		return this.API.get(`branches/${supplierId}/purchaseitems`);
	};

	getBranches = () => {
		return this.API.get(`${this.controller}/branches`);
	};
}

let service = new SuppliersService("suppliers");

export default service;