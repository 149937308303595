import Api from "./Api";

class TransfersService extends Api {
	constructor(controller) {
		super(controller);
	}

	save = model => {
		return this.API.post(`${this.controller}`, model);
	};

	receive = model => {
		return this.API.post(`transfer-receipt`, model);
	};

	search = (start, end, status, id) => {
		return this.API.get(`${this.controller}/search?start=${start.toJSON()}&end=${end.toJSON()}&status=${status || 0}&id=${id || 0}`);
	};

	getById = (id) => {
		return this.API.get(`${this.controller}/${id}`);
	};

	getPosibleStorageItems = (storageId, destStorageId) => {
		return this.API.get(`${this.controller}/GetItemsTransferableBetweenStorages/${storageId}/${destStorageId}`);
	};
}

let service = new TransfersService("transfer");

export default service;