import React, { Component } from 'react';
import PropTypes from 'prop-types';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';

//import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';

//Services
import suppliersService from "services/suppliersService";
import storagesService from "services/storagesService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class BranchStoragesItemsView extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
    };

    state = {
        items: [],
        storages: [],
        showClassificationsOfStorage: null,
        columns: [
            {
                title: "ID",
                field: "itemId",
                editable: 'never',
            },
            {
                title: "Nombre",
                field: 'detail'
            },
            {
                title: "Código SAP",
                field: 'sapCode'
            },
        ],
    };


    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const response = await suppliersService.getStorageItems(this.props.id);
        if (response.ok && response.data.status != "Fail") {
            this.setState({ items: response.data.data.items });
            this.setState({ storages: response.data.data.storages });
        }
    }

    getColumns = () => {
        const {
            storages,
        } = this.state;

        let columns = [
            {
                title: "ID",
                field: "itemId",
                editable: 'never',
            },
            {
                title: "Nombre",
                field: 'detail'
            },
            {
                title: "Código SAP",
                field: 'sapCode'
            },
        ];

        storages.forEach(s => {
            columns.push({
                title: s.storageName,
                field: `storages-${s.storageId}`,
                type: 'bool',
                initialEditValue: true,
                render: row => (
                    <Checkbox
                        key={`I${row.itemId}-S${s.torageId}`}
                        checked={row.storages.includes(s.storageId)}
                        onChange={async (e) => {
                            const value = e.target.checked;

                            //const newItems = [...this.state.items];
                            // const item = newItems.find(x => x.itemId === row.itemId);

                            if (value) {
                                await suppliersService.addStorageItem({
                                    itemId: row.itemId,
                                    storageId: s.storageId,
                                    isActive: true,
                                });

                                // if (!item.storages.find(x => x == s.storageId)) {
                                //     item.storages.push(s.storageId);
                                // }
                            }
                            else {
                                // item.storages = item.storages ? item.storages.filter(id => id != s.storageId) : [];
                                await suppliersService.deleteStorageItem({
                                    itemId: row.itemId,
                                    storageId: s.storageId,
                                    isActive: false,
                                });
                            }

                            //this.setState({ items: newItems });

                            this.getData();
                        }}
                    />
                ),
            });
        });

        return columns;
    }

    getTitle = () => {
        return "Almacenes";
    }

    save = async (newData) => {
        newData.supplierId = this.props.id;

        if (!newData.sapCode) {
            this.props.addNotification({ message: 'Codigo de SAP es requerido', options: { variant: "error" } });
            return Promise.reject();
        }

        if (!newData.name) {
            this.props.addNotification({ message: 'Nombre de almacen es requerido', options: { variant: "error" } });
            return Promise.reject();
        }

        const response = await storagesService.save(newData);


        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    renderTable = () => {
        const {
            items,
            loading,
        } = this.state;

        const columns = this.getColumns();

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={columns}
                        items={items}
                        options={{
                            search: true,
                            paging: false,
                            selection: false,
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <Portlet className={classes.root}>
                        <PortletContent noPadding>
                            {
                                isLoading
                                    ? <CircularProgress />
                                    : this.renderTable()
                            }
                        </PortletContent>
                    </Portlet>
                </div>
            </div>
        );
    }
}

export default WithNotifications(withStyles(styles)(BranchStoragesItemsView));