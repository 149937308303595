import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import { DatePicker } from "@material-ui/pickers";
import Swal from 'sweetalert2';

import {
	withStyles,
	AppBar,
	Tab,
	Tabs,
	Grid,
	CircularProgress,
	InputLabel,
	TextField,
	CardContent,
	Card,
	Button,
} from '@material-ui/core';

import Modal from 'components/Modal';

import { Dashboard as DashboardLayout } from 'layouts';

import {
	Portlet,
	PortletContent,
	TabPanel,
	Select,
	Table,
} from 'components';

import {
	InfoOutlined,
} from '@material-ui/icons';
import { tabProps } from 'components/TabPanel';

//Services
import { WithNotifications, WithSession } from 'hoc';

import suppliersService from 'services/suppliersService';
import itemsService from 'services/itemsService';
import storagesService from 'services/storagesService';
import purchasesService from 'services/purchasesService';
//Assets
import styles from './styles';

function PurchaseOrderForm(props) {
	const {
		classes,
		title,
		addNotification,
		hideLayout,
		session,
	} = props;

	const [tab, setTab] = useState(0);
	const [loading] = useState(false);
	const [summaryRows, setSummaryRows] = useState([]);
	const [details, setDetails] = useState([]);
	const [itemPresentations, setItemPresentations] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [branches, setBranches] = useState([]);
	const [branchId, setBranchId] = useState(null);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const [supplierId, setSupplierId] = useState(null);
	const [storageId, setStorageId] = useState(0);
	const [statusId, setStatusId] = useState(1);
	const [comentarioCancelacion, setComentarioCancelacion] = useState(null);
	const [tipoPedidoId, setTipoPedidoId] = useState(1);
	const [id] = useState(props.id);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [quantity, setQuantity] = useState('');
	const [obs, setObs] = useState(null);
	const [itemPresentationId, setItemPresentationId] = useState(null);
	const [itemPresentation, setItemPresentation] = useState(null);
	const [itemId, setItemId] = useState(null);
	const [itemObj, setItemObj] = useState(null);
	const [items, setItems] = useState([]);
	const [storages, setStorages] = useState([]);

	const [userCanCancel] = useState(session.roleId == 6);

	const summaryRowsColumns = [
		{
			title: "Almacen",
			field: "storage",
			editable: 'never',
		},
		{
			title: "Total",
			field: "total",
			type: 'currency',
			editable: 'never',
		},
	];

	const detailColumns = [
		{
			title: "Descripción",
			field: "description",
			editable: 'never',
		},
		{
			title: "U/M",
			field: "measurementUnit",
			editable: 'never',
		},
		{
			title: "Cantidad",
			field: "quantity",
			type: 'numeric',
			editable: 'never',
		},
		{
			title: "Precio Unit.",
			field: "price",
			type: 'currency',
			editable: 'never',
		},
		{
			title: "Total",
			field: "total",
			type: 'currency',
			editable: 'never',
		},
		{
			title: "Observaciones",
			field: "observations",
			editable: 'never',
		},
		{
			title: "Proveedor",
			field: "supplier",
			editable: 'never',
		},
		{
			title: "Almacen",
			field: "storage",
			editable: 'never',
			render: (c) => {
				if (c.storage) {
					return (
						<span>{c.storage}</span>
					);
				}

				const storageObj = storages.find(x => x.value === c.storageId);

				return (
					<span>{storageObj ? storageObj.label : ''}</span>
				);
			}
		},
	];

	const [statuses] = useState([
		{
			value: 1,
			label: "Nuevo",
		},
		{
			value: 2,
			label: "Enviado",
		},
		{
			value: 3,
			label: "Terminado",
		},
		{
			value: 4,
			label: "Cancelado",
		}
	]);

	const [tiposPedido] = useState([
		{
			value: 1,
			label: "Materia Prima",
		},
		{
			value: 2,
			label: "Gasto",
		},
		{
			value: 3,
			label: "AC Fijo",
		},
		{
			value: 4,
			label: "Empleado",
		},
		{
			value: 5,
			label: "Fijos",
		},
		{
			value: 6,
			label: "Perifericos",
		},
		{
			value: 7,
			label: "Urgente",
		},
	]);

	// const getOptions = () => {

	// };

	// const formatCurrency = (number) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number);
	// const formatNumber = (number) => new Intl.NumberFormat('es-MX').format(number);

	const init = async () => {
		// loadItemFromUrl();

		suppliersService.getInternal().then(response => {
			setBranches(response && response.data ? response.data.data.map((i) => ({ value: i.supplierId, label: i.supplierName })) : []);
		});

		suppliersService.getExternal().then(response => {
			setSuppliers(response && response.data ? [{ value: 0, label: 'Mejor proveedor' }, ...response.data.data.map((i) => ({ value: i.supplierId, label: i.alias }))] : []);
		});
	};

	const cleanScreen = () => {
		setDetails([]);
		setQuantity('');
		setBranchId(null);
		setSupplierId(null);
		setDeliveryDate(new Date());
		setStorageId(null);
		setTipoPedidoId(1);
		setTab(0);
		setSummaryRows([]);
	};

	const loadItem = async () => {
		if (!id) {
			cleanScreen();
			return;
		}

		try {
			const response = await purchasesService.getById(id);

			if (response && response.data && response.data.data) {
				console.log('new item:', response.data.data);

				setBranchId(response.data.data.branchId);
				setSupplierId(response.data.data.supplierId);
				setDeliveryDate(new Date(response.data.data.deliveryDate));
				setStatusId(response.data.data.statusId);
				//setTipoPedidoId();
				setDetails(response.data.data.purchaseDetails.map((i) => ({
					...i,
					description: i.itemDescription,
					measurementUnit: i.itemMeasurementUnit,
					quantity: i.quantityRequested,
					price: i.itemUnitPrice,
					total: i.totalAmount,
					observations: i.notes,
					supplier: response.data.data.supplierName,
				})));

				// await getStorages();
				// setTimeout(calcSummary, 1000);
			}
			// const expensesP = clientsService.getExpenses(clientId);
			// const documentsP = clientsService.getDocuments(clientId);
			// const lunchRoomsP = clientsService.getLunchRooms(clientId);
			// const itemPricesReportP = clientsService.getItemPricesReport(clientId);
			// const clientGroupsP = historicalPriceService.getClientGroups(clientId);
			// const menuP = clientsService.getMenu(clientId);
			// const serviceP = clientsService.getService(clientId);

			// const results = {
			// 	expenses: await expensesP,
			// 	documents: await documentsP,
			// 	lunchRooms: await lunchRoomsP,
			// 	//salesReport: await salesReportP,
			// 	itemPricesReport: await itemPricesReportP,
			// 	clientGroups: await clientGroupsP,
			// 	menu: await menuP,
			// 	service: await serviceP,
			// };

			// const newItem = {
			// 	...client,
			// 	loaded: true,
			// 	expenses: results.expenses.data.data && results.expenses.data.data.length ? results.expenses.data.data : [],
			// 	documents: results.documents.data.data && results.documents.data.data.length ? results.documents.data.data : [],
			// 	lunchRooms: results.lunchRooms.data.data && results.lunchRooms.data.data.length
			// 		? [{ lunchRoomId: 0, lunchRoomName: "Todas" }, ...results.lunchRooms.data.data]
			// 		: [{ lunchRoomId: 0, lunchRoomName: "Todas" }],
			// 	salesReport: [],
			// 	itemPricesReport: results.itemPricesReport.data.data && results.itemPricesReport.data.data.length ? results.itemPricesReport.data.data : [],
			// 	clientGroups: results.clientGroups.data.data && results.clientGroups.data.data.length ? results.clientGroups.data.data : [],
			// 	menu: results.menu.data && results.menu.data.data ? results.menu.data.data : { ...emptyItem.menu },
			// 	service: results.service.data && results.service.data.data ? results.service.data.data : { ...emptyItem.service },
			// 	saleItems: [],
			// };

			// setItem(newItem);

			// eslint-disable-next-line
		}
		catch (e) {
			console.log(e);
		}
	};

	const getItems = async () => {
		setItems([]);

		if (!branchId)
			return;

		try {
			const response = await suppliersService.getSupplierItems(branchId);
			setItems(response && response.data ? response.data.data.map((i) => ({ value: i.itemId, label: i.item.detail || '', ...i })) : []);
		} catch (e) {
			console.log(e);
		}
	};

	const getStorages = async () => {
		setStorages([]);

		if (!branchId)
			return;

		try {
			const response = await storagesService.getById(branchId);
			setStorages(response && response.data ? response.data.data.map((i) => ({ value: i.storageId, label: i.name, ...i })) : []);
		} catch (e) {
			console.log(e);
		}
	};

	const getItemPresentations = async () => {
		setItemPresentations([]);

		if (!itemId)
			return;

		try {
			const response = await itemsService.getAllSupplierItems(supplierId || 0, itemId);
			setItemPresentations(response && response.data ? response.data.data.map((i) => ({ value: i.itemExternalId, label: `${i.itemExternalDescription} (${i.supplierName})`, ...i })) : []);

			if (response && response.data && response.data.data && response.data.data.length > 0) {
				const first = response.data.data[0];
				setItemPresentation(first);
				setItemPresentationId(first.id);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const calcSummary = () => {
		if (!branchId || !storages || !storages.length) {
			setSummaryRows([]);
			return;
		}

		let rows = [];
		storages.forEach(i => {
			const item = {
				storage: i.label,
				total: 0,
			};

			const storageItems = details.filter(x => x.storageId === i.value);

			if (storageItems) {
				storageItems.forEach(i => {
					item.total += parseFloat(i.quantity) * parseFloat(i.price);
				});
			}

			rows.push(item);
		});

		setSummaryRows(rows);
	};

	// const loadItem = async () => {
	// 	if (!id) {
	// 		cleanScreen();
	// 		return null;
	// 	}

	// 	try {
	// 		const response = await purchaseOrdersService.getData(id);
	// 		if (
	// 			!response.data ||
	// 			response.data.status == 'Fail' ||
	// 			!response.data.data
	// 		) {
	// 			return true;
	// 		}

	// 		return {
	// 			...response.data.data,
	// 			billData: { ...emptyItem.billData, ...response.data.data.billData, },
	// 			contacts: [...emptyItem.contacts, ...response.data.data.contacts],
	// 			salesReport: [],
	// 			itemPricesReport: [],
	// 			lunchRooms: [],
	// 			menu: { ...emptyItem.menu, agreements: "", guarantee: "" },
	// 			service: { ...emptyItem.service, agreement: "" },
	// 			loaded: true,
	// 		};
	// 	}
	// 	catch (e) {
	// 		// eslint-disable-next-line
	// 		console.log(e);
	// 		return null;
	// 	}
	// };

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		calcSummary();
	}, [storages.length, branchId, details]);

	useEffect(() => {
		cleanScreen();
		loadItem();
	}, [id]);

	// useEffect(() => {
	// 	if (propsId) {
	// 		// loadItemFromUrl();
	// 	} else {
	// 		cleanScreen();
	// 	}
	// }, [propsId]);

	useEffect(() => {
		getStorages();
		getItems();
	}, [branchId]);

	useEffect(() => {
	}, [supplierId]);

	useEffect(() => {
		getItemPresentations();
	}, [itemId]);

	useEffect(() => {
		calcSummary();
	}, [details.length, supplierId]);

	const closeDetailsModal = () => {
		setShowDetailsModal(false);

		setItemId(null);
		setItemObj(null);
		setItemPresentationId(null);
		setItemPresentation(null);
		setQuantity(null);
		setObs(null);
	};

	const addDetail = () => {
		const existingItem = details.find((x) => x.itemId == itemObj.id);
		if (existingItem) {
			addNotification({ message: 'El artículo ya esta en la orden de compra', options: { variant: "warning" } });
			return;
		}
		const itemStorage = storages.find(x => x.value == itemObj.storageId);

		// eslint-disable-next-line
		// debugger;

		const newDetail = {
			itemId: itemObj ? itemObj.id : null,
			supplierItemId: itemObj && itemObj.item ? itemObj.item.itemId : null,
			itemPresentationId: itemPresentation.itemExternalId,
			measurementUnit: itemPresentation.measurementUnit,
			description: itemPresentation.itemDescription,
			presentation: itemPresentation.description,
			quantity,
			storageId: itemObj ? itemObj.storageId : null,
			storage: itemStorage ? itemStorage.label : "N/A",
			price: itemPresentation.unitPrice,
			total: itemPresentation.unitPrice * quantity,
			supplierId: itemPresentation.supplierId,
			supplier: itemPresentation.supplierName,
			observations: obs,
		};

		setDetails([...details, newDetail]);
		closeDetailsModal();

		const addBtn = document.querySelector('button > span > #add-detail');
		if (addBtn) {
			addBtn.focus();
		}
	};

	const cancel = async () => {
		if (!comentarioCancelacion)
			return;

		const response = await purchasesService.cancel(id, comentarioCancelacion);

		if (!response || !response.data || !response.data.result) {
			Swal.fire(
				'Error',
				'No se pudo cancelar la orden de compra, favor de validar con sistemas',
				'error'
			);
			return;
		}

		Swal.fire(
			'Correcto',
			'Orden de compra cancelada correctamente',
			'success'
		);
	};

	const deleteDetail = (e, row) => {
		console.log(e, row);
		setDetails([...details.filter(x => x.itemId != row.itemId)]);
	};

	const renderDetailsModal = () => {
		return (
			<Modal
				title="Agregar artículo"
				isOpen={showDetailsModal}
				onRequestClose={closeDetailsModal}
				style={{ width: '450px', height: '565px' }}
			>
				<Grid container spacing={2} style={{ padding: 10 }} justifyContent="center" alignItems="center">
					<Grid item xs={12}>
						<InputLabel>Artículo</InputLabel>
						<Select
							isClearable
							value={itemId}
							onChange={(selected) => {
								setItemId(selected.value);
								setItemObj(selected);
								setItemPresentationId(null);
								setItemPresentation(null);
								setQuantity(null);
							}}
							onClear={() => {
								setItemId(null);
								setItemObj(null);
								setItemPresentationId(null);
								setItemPresentation(null);
								setObs(null);
							}}
							options={storageId ? items.filter(x => x.storageId === storageId) : items}
							styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
						// menuPortalTarget={document.body}
						// menuPosition={'absolute'}
						// menuPlacement={'bottom'}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Opciones proveedor</InputLabel>
						<Select
							key={itemId || 0}
							isClearable
							isDisabled={!itemId}
							value={itemPresentationId}
							onChange={(selected) => {
								setItemPresentationId(selected.value);
								setItemPresentation(selected || null);
							}}
							onClear={() => {
								setItemPresentationId(null);
								setItemPresentation(null);
							}}
							options={itemPresentations}
							styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
						// menuPortalTarget={document.body}
						// menuPosition={'absolute'}
						// menuPlacement={'bottom'}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Cantidad por empaque</InputLabel>
						<TextField
							disabled
							value={itemPresentation ? itemPresentation.quantity : '1'}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Precio por unidad</InputLabel>
						<TextField
							disabled
							value={itemPresentation ? itemPresentation.unitPrice : ''}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<InputLabel>Cantidad</InputLabel>
						<TextField
							disabled={!itemId || !itemPresentationId}
							value={quantity}
							onChange={(e) => setQuantity(e.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<InputLabel>Unidad de medida</InputLabel>
						<TextField
							disabled
							value={itemPresentation ? itemPresentation.measurementUnit : ''}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Observaciones</InputLabel>
						<TextField
							disabled={!itemId || !itemPresentationId}
							value={obs}
							onChange={(e) => setObs(e.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							variant="contained"
							color="primary"
							onClick={addDetail}
							disabled={!itemId || !itemPresentationId || !quantity}
						>
							Agregar
						</Button>
					</Grid>
				</Grid>
			</Modal>
		);
	};

	const save = async () => {
		const model = {
			deliveryDate,
			branchId,
			supplierId: supplierId || null,
			statusId: 1,
			notes: '',
			purchaseDetails: details.map((i) => ({
				supplierId: i.supplierId,
				purchaseDetailId: 0,
				purchaseId: 0,
				itemExternalId: i.itemPresentationId,
				itemId: i.supplierItemId,
				itemDescription: i.description,
				itemQuantity: parseFloat(i.quantity),
				itemMeasurementUnit: i.measurementUnit,
				itemUnitPrice: i.price,
				quantityRequested: parseFloat(i.quantity),
				totalAmount: parseFloat(i.quantity) * i.price,
				storageId: i.storageId,
				notes: i.notes || '',
				statusId: 1,
			})),
		};

		const isMulti = new Set(details.map((x) => x.supplierId)).size > 1;

		//return;

		// eslint-disable-next-line
		debugger;

		// if (false) {
		if (saving || !model.branchId || !model.purchaseDetails.length) {
			return;
		}

		setSaving(true);

		try {
			let createMethod = purchasesService.create;
			if (isMulti) {
				createMethod = purchasesService.createMulti;
			}

			const response = await createMethod(model);

			if (!response || !response.data || !response.data.data || !response.data.data) {
				props.addNotification({ message: `Error creando orden${isMulti ? 'es' : ''} de compra`, options: { variant: "error" } });
				setSaving(false);
				return;
			}
			else {
				if (!isMulti) {
					Swal.fire(
						'Correcto',
						'Orden de compra creada con folio: ' + response.data.data.folio,
						'success'
					);
				}
				else {
					Swal.fire(
						'Correcto',
						'Ordenes de compra creadas con folios: ' + response.data.data.join(','),
						'success'
					);
				}
				cleanScreen();
			}
		} catch (e) {
			console.log(e);
		}

		setSaving(false);
		// }
	};

	const renderForm = () => {
		return (
			<form className="contracts-form" key={id}>
				{renderDetailsModal()}
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Empresa" value="AUPSA" InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Sucursal</InputLabel>
										<Select
											key={branchId}
											isClearable
											value={branchId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setBranchId(selected.value);
												} else {
													setBranchId(null);
													cleanScreen();
												}

												setStorageId(null);
											}}
											onClear={() => {
												setBranchId(null);
											}}
											options={branches}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										{/* <TextField fullWidth disabled label="Unidad" value={''} InputLabelProps={{ shrink: true }} /> */}
									</Grid>
									<Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
										<Grid
											direction="column"
											justify="center"
										>
											<InputLabel>Fecha</InputLabel>
											<DatePicker
												format="DD/MM/YYYY"
												value={deliveryDate}
												onChange={date => setDeliveryDate(date)}
												autoOk
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Proveedor</InputLabel>
										<Select
											key={supplierId}
											isDisabled={details.length > 0 || id}
											isClearable
											value={supplierId}
											onChange={(selected) => {
												if (selected) {
													setSupplierId(selected.value);
												} else {
													setSupplierId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setSupplierId(null);
											}}
											options={suppliers}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										{/* <TextField fullWidth disabled label="Mejor proveedor" value={''} InputLabelProps={{ shrink: true }} /> */}
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Almacen</InputLabel>
										<Select
											key={storageId || branchId}
											isClearable
											value={storageId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setStorageId(selected.value);
												} else {
													setStorageId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setStorageId(null);
											}}
											options={storages}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Status</InputLabel>
										<Select
											isDisabled
											isClearable
											value={statusId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setStatusId(selected.value);
												} else {
													setStatusId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setStatusId(null);
											}}
											options={statuses}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Tipo pedido</InputLabel>
										<Select
											isClearable
											value={tipoPedidoId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setTipoPedidoId(selected.value);
												} else {
													setTipoPedidoId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setTipoPedidoId(null);
											}}
											options={tiposPedido}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.root} spacing={2}>
							<Grid item className={classes.item} xs={12} md={7}>
								<Table
									title="Resumen"
									isLoading={loading}
									columns={summaryRowsColumns}
									items={summaryRows}
									options={{
										paging: false,
										search: false,
										selection: false,
									}}
								/>
							</Grid>
							{
								userCanCancel && statusId && statusId == 1 &&
								<Grid item className={classes.item} xs={12} md={2}>
									<Button
										variant="contained"
										color="secondary"
										onClick={cancel}
										sx={{ backgroundColor: 'red' }}
										disabled={!comentarioCancelacion}
									>
										Cancelar
									</Button>
								</Grid>
							}
							{
								userCanCancel && statusId && statusId == 1 &&
								<Grid item className={classes.item} xs={12} md={3}>
									<TextField
										value={comentarioCancelacion}
										onChange={(e) => setComentarioCancelacion(e.target.value)}
										id="outlined-multiline-static"
										label="Comentarios de cancelación"
										multiline
										minRows={3}
										variant="outlined"
										fullWidth
									/>
								</Grid>
							}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.root} spacing={1}>
							<Grid item className={classes.item} xs={12}>
								<Table
									title="Detalle"
									isLoading={loading}
									columns={detailColumns}
									items={details}
									options={{
										paging: false,
										search: false,
										selection: false,
									}}
									actions={
										items && items.length > 0 && supplierId != null && !id
											? [
												{
													icon: 'add',
													iconProps: { id: 'add-detail' },
													tooltip: "Agregar",
													isFreeAction: true,
													onClick: () => setShowDetailsModal(true),
												},
												{
													icon: 'delete',
													iconProps: { color: 'red' },
													tooltip: "Quitar",
													isFreeAction: false,
													onClick: (e, row) => deleteDetail(e, row),
												}
											]
											: []
									}
								/>
							</Grid>
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{!id && supplierId != null && details && details.length > 0 &&
									<Button variant="outlined" color="primary" onClick={save}>
										{
											new Set(details.map((x) => x.supplierId)).size > 1
												? 'Crear ordenes de compra'
												: 'Crear orden de compra'
										}
									</Button>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderTabs = () => {
		return (
			<div>
				<AppBar position="static" color="default">
					<Tabs
						key={id}
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab label="General" icon={<InfoOutlined />} {...tabProps(0)} />
					</Tabs>
				</AppBar>
				<TabPanel value={tab} index={0} boxStyle={{ paddingTop: '0px' }}>
					{renderForm()}
				</TabPanel>
			</div>
		);
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
						? <CircularProgress />
						: renderTabs()
				}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	if (hideLayout) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Orden de compra"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

PurchaseOrderForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

PurchaseOrderForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(WithSession(withRouter(withStyles(styles)(PurchaseOrderForm))));
