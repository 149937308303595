import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import TranformationsForm from "./../Form";

// UI Components
import { DatePicker } from "@material-ui/pickers";
import {
    withStyles,
    Grid,
    InputLabel,
    Button,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
// import inventoriesService from 'services/inventoriesService';
import suppliersService from 'services/suppliersService';
import storagesService from 'services/storagesService';
import transformationsService from 'services/transformationsService';

//Assets
import styles from './styles';
import { formatNumber } from 'utils/numbers';
// import mockData from '../mockData';

class SearchTransformations extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().toDate(),
        endDate: Moment().toDate(),
        loading: false,
        branches: [],
        storages: [],
        items: [],
        details: [],
        storageId: null,
        statusId: 0,
        itemId: null,
        loadingDetails: false,
        open: false,
        transformations: [],
    };

    columns = [
        {
            title: "Id",
            field: 'productionOrderId',
        },
        {
            title: "Tipo",
            field: 'type',
            render: r => <span>{r.requestedItem ? "(A)" : "(M)"}</span>
        },
        {
            title: "Artículo solicitado",
            field: 'requestedItem',
            render: r => <span>{r.requestedItem ? r.requestedItem : ""}</span>
        },
        {
            title: "Cantidad",
            field: 'requestedQuantity',
            render: r => <span>{r.requestedQuantity ? formatNumber(r.requestedQuantity) : ""}</span>
        },
        {
            title: "Artículo base",
            field: 'baseItem',
            render: r => <span>{r.baseItem ? r.baseItem : ""}</span>
        },
        {
            title: "Cantidad",
            field: 'baseQuantity',
            render: (row) => <span>{formatNumber(row.baseQuantity)}</span>
        },
        {
            title: "U/M",
            field: 'measurementUnit',
        },
        {
            title: "Status",
            field: 'status',
        },
        {
            title: "Procesada por",
            field: 'processedByFullName',
        },
        {
            title: " ",
            field: 'actions',
            render: (r) => (
                <Button
                    variant="contained"
                    color={r.statusId < 4 ? "primary" : "secondary"}
                    onClick={() => this.setState({ idToShow: r.productionOrderId, open: true })}
                    size="small"
                >
                    {r.statusId < 4 ? "Editar" : "Ver"}
                </Button>
            )
        },
    ];

    statuses = [
        { value: 0, label: 'Todo' },
        { value: 1, label: 'Nueva' },
        { value: 2, label: 'En Proceso' },
        { value: 3, label: 'En Validación' },
        { value: 4, label: 'Cerrada' },
    ];

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        this.getBranches();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            branchId,
            storageId,
            startDate,
            endDate,
            statusId,
        } = this.state;

        if (prevState.branchId != branchId) {
            this.getStorages();
        }

        if (prevState.storageId != storageId) {
            this.getItems();
        }

        if (prevState.startDate != startDate || prevState.endDate != endDate || prevState.branchId != branchId || prevState.statusId != statusId) {
            this.search();
        }
    }

    getStatus = (statusId) => {
        const status = this.statuses.find(x => x.value == statusId);
        return status ? status.label : "";
    }

    getBranches = async () => {
        const response = await suppliersService.getInternal();
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                branches: response.data.data.map((i) => ({
                    value: i.supplierId,
                    label: i.supplierName,
                }))
            });

            if (response.data && response.data.data && response.data.data.length > 0) {
                this.setState({ branchId: response.data.data[0].supplierId });
            }
        }
    }

    getStorages = async () => {
        const {
            branchId
        } = this.state;

        this.setState({ storages: [], storageId: null });

        if (!branchId) {
            return;
        }

        const response = await storagesService.getById(branchId);
        if (!response.ok || response.data.status == "Fail") {
            return;
        }

        let storageId = null;

        if (response && response.data && response.data.data && response.data.data.length) {
            const almacenesCarnes = response.data.data.filter(x => x.name.toUpperCase().includes("CARNE"));
            if (almacenesCarnes && almacenesCarnes.length > 0) {
                storageId = almacenesCarnes[0].storageId;
            }
        }

        this.setState({
            storages: response.data.data.map((i) => ({
                value: i.storageId,
                label: i.name,
            })),
            storageId,
        });
    }

    getItems = async () => {
        const {
            storageId,
        } = this.state;

        this.setState({ items: [], itemId: null });

        if (!storageId) {
            return;
        }

        const response = await storagesService.getItems(storageId);
        if (!response.ok || response.data.status == "Fail") {
            return;
        }

        this.setState({
            items: response.data.data.map((i) => ({
                value: i.itemId,
                label: i.detail,
            })),
        });
    }

    search = async () => {
        const {
            startDate,
            endDate,
            branchId,
            statusId,
            itemId,
        } = this.state;

        if (!branchId || !startDate || !endDate) {
            return;
        }

        this.setState({ loading: true });
        this.setState({ items: [] });

        const filters = {
            branchId,
            startDate,
            endDate,
            statusId,
            itemId,
        };

        const response = await transformationsService.search(filters);

        this.setState({
            transformations: response.data.data,
        });

        // try {
        //     const response = await inventoriesService.getPendingItemsToTransform(date, branchId);
        //     this.setState({ items: response && response.data ? response.data.data : [] });
        // } catch (e) {
        //     console.log(e);
        // }

        this.setState({ loading: false });
    }

    getTitle = () => {
        return "Transformaciones";
    }

    renderFilters = () => {
        const {
            branchId,
            storageId,
            branches,
            items,
            startDate,
            endDate,
            itemId,
            statusId,
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={6} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha Inicial</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={startDate}
                            onChange={v => this.setState({ startDate: v })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha final</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={endDate}
                            onChange={v => this.setState({ endDate: v })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Sucursal</InputLabel>
                        <Select
                            key={branches.length}
                            value={branchId}
                            onChange={(selected) => {
                                this.setState({ branchId: selected.value });
                            }}
                            options={branches}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Artículo</InputLabel>
                        <Select
                            key={`B${branchId}-S${storageId}`}
                            value={itemId}
                            onChange={(selected) => {
                                this.setState({ itemId: selected.value });
                            }}
                            options={items}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Estado</InputLabel>
                        <Select
                            value={statusId}
                            onChange={(selected) => {
                                this.setState({ statusId: selected.value });
                            }}
                            options={this.statuses}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            transformations,
            selectedItems,
            copiyng,
            loading,
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : transformations}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            search: false,
                            actionsColumnIndex: -1,
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                        actions={[
                            {
                                icon: 'add',
                                isFreeAction: true,
                                tooltip: "Crear hoja de producción",
                                onClick: () => this.setState({ open: true, idToShow: null })
                            }
                        ]}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            idToShow,
            open,
            storageId,
            branchId,
        } = this.state;

        if (!open)
            return null;

        return (
            <Modal
                title="Hoja de producción"
                isOpen={open}
                onRequestClose={() => {
                    this.setState({ idToShow: null, open: false });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <TranformationsForm
                    key={idToShow || 0}
                    id={idToShow}
                    branchId={branchId}
                    storageId={storageId}
                    hideLayout
                    onClose={() => this.setState({ idToShow: null, open: false })}
                    onSave={() => {
                        this.setState({ idToShow: null, open: false });
                        this.search();
                    }}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(SearchTransformations))))));