import API from './Api';

class PurchasesReceiptService extends API {
	constructor(controller) {
		super(controller);
	}

	save = (model) => {
		return this.API.post(`${this.controller}`, model);
	};
}

let service = new PurchasesReceiptService('PurchaseReceipt');

export default service;
