import React, { Component } from 'react';
import classNames from 'classnames';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
	withStyles,
	Hidden,
	AppBar,
	Tabs,
	Tab,
	Typography,
	Box,
	Grid,
	TextField,
	Button,
	InputLabel,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletContent,
	Select,
	Table,
} from 'components';
import { withSnackbar } from 'notistack';

import itemsService from "services/itemsService";

import {
	InfoRounded,
} from '@material-ui/icons';

import styles from './styles';

import { WithNotifications } from 'hoc';

const tabProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

class EditForm extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		id: PropTypes.number,
		onSave: PropTypes.func
	};

	static defaultProps = {
		id: null,
		onSave: () => { },
	};

	emptyItem = {
		supplierId: null,
		address: "",
		alias: "",
		code: null,
		contactName: "",
		contactPhoneNumber: "",
		contactPhoneNumber2: "",
		createdBy: null,
		createdDate: null,
		creationDate: new Date(),
		creditDays: 0,
		email: null,
		importDate: new Date(),
		isActive: true,
		isExternal: true,
		modifiedBy: null,
		modifiedDate: null,
		rfc: "",
		sapCode: "",
		supplierDescription: "",
		supplierName: "",
		csf: {},
		supplierItems: [],
		supplierItemsBranches: [],
	};

	columns = [
		{
			title: "Asignado",
			field: 'isActive',
			type: 'bool',
			render: row => (
				<Checkbox
					checked={row.isActive}
					onChange={(e, v) => this.setState(ps => {
						const newItems = [...ps.item.supplierItemsBranches];
						const item = newItems.find(x => x.supplierId == row.supplierId);
						item.isActive = v;

						return { item: { ...ps.item, supplierItemsBranches: newItems }, key: new Date().toISOString() };
					})}
				/>
			)
		},
		{
			title: "Sucursal",
			field: "branchName",
			editable: 'never',
		},
		{
			title: "Almacen",
			field: 'storageId',
			render: row => (
				<Select
					value={row.storageId}
					onChange={selected => this.setState(ps => {
						const newItems = [...ps.item.supplierItemsBranches];
						const item = newItems.find(x => x.supplierId == row.supplierId);
						item.storageId = selected ? selected.value : null;
						item.clasificationId = null;

						return { item: { ...ps.item, supplierItemsBranches: newItems }, key: new Date().toISOString() };
					})}
					options={this.props.storages || []}
				/>
			)
		},
		{
			title: "Clasificación",
			field: 'clasificationId',
			render: row => (
				<Select
					key={row.storageId || 0}
					disabled={!row.storageId}
					isDisabled={!row.storageId}
					value={row.clasificationId}
					onChange={selected => this.setState(ps => {
						const newItems = [...ps.item.supplierItemsBranches];
						const item = newItems.find(x => x.supplierId == row.supplierId);
						item.clasificationId = selected ? selected.value : null;

						return { item: { ...ps.item, supplierItemsBranches: newItems }, key: new Date().toISOString() };
					})}
					options={this.props.clasifications.filter(x => x.storageId == row.storageId) || []}
				/>
			)
		},
	];

	state = {
		tab: 0,
		users: [],
		item: { ...this.emptyItem },
		items: [],
		loading: false,
		loadingItems: false,
		regimes: [],
		supplierTypes: [],
		isrTypes: [],
		ivaTypes: [],
		accountExecutives: [],
		supplierItems: [],
		key: new Date().toISOString(),
	};

	componentDidMount = () => {
		this.getData(this.props.id);
	}

	getData = async (id) => {
		const {
			branches,
		} = this.props;

		if (!id) {
			return;
		}

		try {
			const response = await itemsService.getItemById(id);

			if (response.ok && response.data.status != "Fail") {
				const item = response.data.data;
				// eslint-disable-next-line
				console.log(item);
				item.supplierItemsBranches = [];
				branches.forEach(branch => {
					let supplierItem = item.supplierItems.find(x => x.supplierId == branch.supplierId);
					if (supplierItem) {
						supplierItem.branchName = branch.supplierName;
						if (!supplierItem.isActive) {
							supplierItem.clasificationId = null;
							supplierItem.storageId = null;
							supplierItem.isActive = false;
						}
					}
					else {
						supplierItem = {
							branchName: branch.supplierName,
							isActive: false,
							supplierId: branch.supplierId,
							clasificationId: null,
							storageId: null,
						};
					}

					item.supplierItemsBranches.push(supplierItem);
				});

				this.setState({ key: new Date().toISOString(), item: response.data.data });
			}
		}
		catch (e) {
			// eslint-disable-next-line
			console.log(e);
		}
	}

	save = async () => {
		const {
			item,
		} = this.state;

		const {
			onSave,
		} = this.props;

		const model = {
			...item,
			supplierItems: item.supplierItemsBranches,
		};

		console.log(model);

		this.setState({ loading: true });

		try {
			const response = await itemsService.upsert(model);
			if (response && response.data && response.data.data && response.data.data.itemId) {
				this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
				onSave();
			}
		} catch (e) {
			console.log(e);
		}

		this.setState({ loading: false });
	}

	renderGeneralInfo = () => {
		const {
			classes,
			units,
			groups,
			unitKeys,
		} = this.props;

		const {
			item,
			loading,
		} = this.state;

		return (
			<Grid container spacing={2} style={{ padding: 15 }}>
				{
					item.itemId &&
					<Grid item xs={12}>
						<TextField
							label="Id"
							value={item.itemId}
							disabled
						/>
					</Grid>
				}
				<Grid item xs={12} md={8}>
					<TextField
						autoFocus
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Nombre"
						value={item.detail}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, detail: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth
						label="Código SAP"
						value={item.sapCode}
						onChange={(e) => this.setState(ps => ({ item: { ...ps.item, sapCode: e.target.value } }))}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<div
						className={classNames(classes.selectRoot)}
					>
						<InputLabel className={classes.inputLabel}>
							Unidad SAT
						</InputLabel>
						<Select
							value={item.unitKey_SAT}
							onChange={selected => this.setState(ps => ({ item: { ...ps.item, unitKey_SAT: selected.value } }))}
							options={unitKeys || []}
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div
						className={classNames(classes.selectRoot)}
					>
						<InputLabel className={classes.inputLabel}>
							U/M
						</InputLabel>
						<Select
							value={item.measurementUnitId}
							onChange={selected => this.setState(ps => ({ item: { ...ps.item, measurementUnitId: selected.value } }))}
							options={units || []}
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div
						className={classNames(classes.selectRoot)}
					>
						<InputLabel className={classes.inputLabel}>
							Grupo
						</InputLabel>
						<Select
							value={item.groupId}
							onChange={selected => this.setState(ps => ({ item: { ...ps.item, groupId: selected.value } }))}
							options={groups || []}
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={12}>
					<FormControlLabel
						control={
							<Checkbox
								key={item ? item.itemId : 0}
								checked={item && item.isBaseItem}
								onChange={(e) => this.setState(ps => ({ item: { ...ps.item, isBaseItem: e.target.checked } }))}
								name="isBaseItem"
								color="primary"
							/>
						}
						label="Es materia prima"
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<FormControlLabel
						control={
							<Checkbox
								key={item ? item.itemId : 0}
								checked={item && item.isTransformedItem}
								onChange={(e) => this.setState(ps => ({ item: { ...ps.item, isTransformedItem: e.target.checked } }))}
								name="isTransformedItem"
								color="primary"
							/>
						}
						label="Es artículo transformado"
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<FormControlLabel
						control={
							<Checkbox
								key={item ? item.itemId : 0}
								checked={item && item.isActive}
								onChange={(e) => this.setState(ps => ({ item: { ...ps.item, isActive: e.target.checked } }))}
								name="isActive"
								color="primary"
							/>
						}
						label="Activo"
					/>
				</Grid>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
					<Button
						variant="contained"
						color="primary"
						disabled={loading}
						onClick={this.save}
					>
						Guardar
					</Button>
				</Grid>
			</Grid>
		);
	}

	renderBranches = () => {
		const {
			item,
			loading,
			key,
		} = this.state;

		return (
			<Grid container spacing={2} style={{ padding: 15 }} key={key}>
				<Grid item xs={12}>
					<Table
						isLoading={loading}
						columns={this.columns}
						items={item.supplierItemsBranches}
						options={{
							paginate: false,
						}}
					/>
				</Grid>
			</Grid>
		);
	}

	render() {
		const {
			classes,
		} = this.props;

		const {
			tab,
		} = this.state;

		return (
			<Portlet className={classes.root}>
				<PortletContent noPadding>
					<AppBar position="static" color="default">
						<Tabs
							value={tab}
							onChange={(e, tab) => {
								this.setState({ tab });
							}}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab
								icon={<InfoRounded />}
								label={<Hidden xsDown>Datos generales</Hidden>}
								{...tabProps(0)}
							/>
							<Tab
								icon={<InfoRounded />}
								label={<Hidden xsDown>Sucursales</Hidden>}
								{...tabProps(1)}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={tab} index={0}>
						{this.renderGeneralInfo}
					</TabPanel>
					<TabPanel value={tab} index={1}>
						{this.renderBranches}
					</TabPanel>
				</PortletContent>
			</Portlet>
		);
	}
}

export default WithNotifications(withStyles(styles)(withSnackbar(EditForm)));