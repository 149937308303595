import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// UI Components
import { DatePicker } from "@material-ui/pickers";
import Swal from 'sweetalert2';

import {
	withStyles,
	AppBar,
	Tab,
	Tabs,
	Grid,
	CircularProgress,
	InputLabel,
	TextField,
	CardContent,
	Card,
	Button,
} from '@material-ui/core';

import Modal from 'components/Modal';

import { Dashboard as DashboardLayout } from 'layouts';

import {
	Portlet,
	PortletContent,
	TabPanel,
	Select,
	Table,
} from 'components';

import {
	InfoOutlined,
} from '@material-ui/icons';
import { tabProps } from 'components/TabPanel';

//Services
import { WithNotifications } from 'hoc';
import transfersService from 'services/transfersService';
import storagesService from 'services/storagesService';
import purchasesService from 'services/purchasesService';
import suppliersService from 'services/suppliersService';

//Assets
import styles from './styles';

function Transfer(props) {
	const {
		classes,
		title,
		addNotification,
		hideLayout,
	} = props;

	const [tab, setTab] = useState(0);
	const [loading] = useState(false);
	const [details, setDetails] = useState([]);
	const [branchId, setBranchId] = useState(null);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const [storageId, setStorageId] = useState(0);
	const [destinationStorageId, setDestinationStorageId] = useState(0);
	const [id] = useState(props.id);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [quantity, setQuantity] = useState('');
	const [notes, setNotes] = useState('');
	const [itemId, setItemId] = useState(null);
	const [itemObj, setItemObj] = useState(null);
	const [items, setItems] = useState([]);
	const [storages, setStorages] = useState([]);
	const [branches, setBranches] = useState([]);

	const detailColumns = [
		{
			title: "Id",
			field: "itemId",
			editable: 'never',
		},
		{
			title: "Descripción",
			field: "detail",
			editable: 'never',
		},
		// {
		// 	title: "U/M",
		// 	field: "measurementUnit",
		// 	editable: 'never',
		// },
		{
			title: "Cantidad transferencia",
			field: "quantity",
			type: 'numeric',
			editable: 'never',
		},
		{
			title: "Costo promedio",
			field: "averageCost",
			type: 'currency',
			editable: 'never',
		},
		{
			title: "Cant. Inventario",
			field: "lastQuantity",
			type: 'numeric',
			editable: 'never',
		},
	];

	// const getOptions = () => {

	// };

	// const formatCurrency = (number) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number);
	// const formatNumber = (number) => new Intl.NumberFormat('es-MX').format(number);

	const init = async () => {
		suppliersService.getInternal().then(response => {
			setBranches(response && response.data ? response.data.data.map((i) => ({ value: i.supplierId, label: i.supplierName })) : []);
		});
	};

	const cleanScreen = () => {
		setDetails([]);
		setQuantity('');
		setBranchId(null);
		setDeliveryDate(new Date());
		setStorageId(null);
		setTab(0);
	};

	const loadItem = async () => {
		if (!id) {
			cleanScreen();
			return;
		}

		try {
			const response = await purchasesService.getById(id);

			if (response && response.data && response.data.data) {
				setBranchId(response.data.data.branchId);
				setDeliveryDate(new Date(response.data.data.deliveryDate));
				//setTipoPedidoId();
				setDetails(response.data.data.purchaseDetails.map((i) => ({
					...i,
					description: i.itemDescription,
					measurementUnit: i.itemMeasurementUnit,
					quantity: i.itemQuantity,
					cost: i.itemUnitPrice,
					observations: i.notes,
					supplier: response.data.data.supplierName,
				})));

				// await getStorages();
			}
			// const expensesP = clientsService.getExpenses(clientId);
			// const documentsP = clientsService.getDocuments(clientId);
			// const lunchRoomsP = clientsService.getLunchRooms(clientId);
			// const itemPricesReportP = clientsService.getItemPricesReport(clientId);
			// const clientGroupsP = historicalPriceService.getClientGroups(clientId);
			// const menuP = clientsService.getMenu(clientId);
			// const serviceP = clientsService.getService(clientId);

			// const results = {
			// 	expenses: await expensesP,
			// 	documents: await documentsP,
			// 	lunchRooms: await lunchRoomsP,
			// 	//salesReport: await salesReportP,
			// 	itemPricesReport: await itemPricesReportP,
			// 	clientGroups: await clientGroupsP,
			// 	menu: await menuP,
			// 	service: await serviceP,
			// };

			// const newItem = {
			// 	...client,
			// 	loaded: true,
			// 	expenses: results.expenses.data.data && results.expenses.data.data.length ? results.expenses.data.data : [],
			// 	documents: results.documents.data.data && results.documents.data.data.length ? results.documents.data.data : [],
			// 	lunchRooms: results.lunchRooms.data.data && results.lunchRooms.data.data.length
			// 		? [{ lunchRoomId: 0, lunchRoomName: "Todas" }, ...results.lunchRooms.data.data]
			// 		: [{ lunchRoomId: 0, lunchRoomName: "Todas" }],
			// 	salesReport: [],
			// 	itemPricesReport: results.itemPricesReport.data.data && results.itemPricesReport.data.data.length ? results.itemPricesReport.data.data : [],
			// 	clientGroups: results.clientGroups.data.data && results.clientGroups.data.data.length ? results.clientGroups.data.data : [],
			// 	menu: results.menu.data && results.menu.data.data ? results.menu.data.data : { ...emptyItem.menu },
			// 	service: results.service.data && results.service.data.data ? results.service.data.data : { ...emptyItem.service },
			// 	saleItems: [],
			// };

			// setItem(newItem);

			// eslint-disable-next-line
		}
		catch (e) {
			console.log(e);
		}
	};

	const getItems = async () => {
		setItems([]);

		if (!branchId)
			return;

		try {
			const response = await transfersService.getPosibleStorageItems(storageId, destinationStorageId);
			setItems(response && response.data ? response.data.data.map((i) => ({ value: i.itemId, label: i.detail || '', ...i })) : []);
		} catch (e) {
			console.log(e);
		}
	};

	const getStorages = async () => {
		setStorages([]);

		if (!branchId)
			return;

		try {
			const response = await storagesService.getById(branchId);
			setStorages(response && response.data ? response.data.data.map((i) => ({ value: i.storageId, label: i.name, ...i })) : []);
		} catch (e) {
			console.log(e);
		}
	};


	// const loadItem = async () => {
	// 	if (!id) {
	// 		cleanScreen();
	// 		return null;
	// 	}

	// 	try {
	// 		const response = await purchaseOrdersService.getData(id);
	// 		if (
	// 			!response.data ||
	// 			response.data.status == 'Fail' ||
	// 			!response.data.data
	// 		) {
	// 			return true;
	// 		}

	// 		return {
	// 			...response.data.data,
	// 			billData: { ...emptyItem.billData, ...response.data.data.billData, },
	// 			contacts: [...emptyItem.contacts, ...response.data.data.contacts],
	// 			salesReport: [],
	// 			itemPricesReport: [],
	// 			lunchRooms: [],
	// 			menu: { ...emptyItem.menu, agreements: "", guarantee: "" },
	// 			service: { ...emptyItem.service, agreement: "" },
	// 			loaded: true,
	// 		};
	// 	}
	// 	catch (e) {
	// 		// eslint-disable-next-line
	// 		console.log(e);
	// 		return null;
	// 	}
	// };

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		getStorages();
	}, [branchId]);

	useEffect(() => {
		cleanScreen();
		loadItem();
	}, [id]);

	// useEffect(() => {
	// 	if (propsId) {
	// 		// loadItemFromUrl();
	// 	} else {
	// 		cleanScreen();
	// 	}
	// }, [propsId]);

	useEffect(() => {
		getItems();
	}, [storageId, destinationStorageId]);

	const closeDetailsModal = () => {
		setShowDetailsModal(false);

		setItemId(null);
		setItemObj(null);
		setQuantity(null);
	};

	const addDetail = () => {
		const existingItem = details.find((x) => x.itemId == itemObj.itemId);
		if (existingItem) {
			addNotification({ message: 'El artículo ya esta en la solicitud de transferencia', options: { variant: "warning" } });
			return;
		}

		// eslint-disable-next-line
		// debugger;

		const newDetail = {
			itemId: itemObj.itemId,
			itemMeasurementUnitId: itemObj.measurementUnitId,
			itemMeasurementUnit: itemObj.measurementUnit,
			lastQuantity: itemObj.quantity,
			quantity: quantity,
			averageCost: itemObj.averageCost,
			detail: itemObj.detail,
		};

		setDetails([...details, newDetail]);
		closeDetailsModal();

		const addBtn = document.querySelector('button > span > #add-detail');
		if (addBtn) {
			addBtn.focus();
		}
	};

	const deleteDetail = (e, row) => {
		setDetails([...details.filter(x => x.itemId != row.itemId)]);
	};

	const renderDetailsModal = () => {
		return (
			<Modal
				title="Agregar artículo"
				isOpen={showDetailsModal}
				onRequestClose={closeDetailsModal}
				style={{ width: '450px', height: '565px' }}
			>
				<Grid container spacing={2} style={{ padding: 10 }} justifyContent="center" alignItems="center">
					<Grid item xs={12}>
						<InputLabel>Artículo</InputLabel>
						<Select
							isClearable
							value={itemId}
							onChange={(selected) => {
								setItemId(selected.value);
								setItemObj(selected);
								setQuantity(null);
							}}
							onClear={() => {
								setItemId(null);
								setItemObj(null);
							}}
							options={items}
							styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
						// menuPortalTarget={document.body}
						// menuPosition={'absolute'}
						// menuPlacement={'bottom'}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Costo promedio</InputLabel>
						<TextField
							disabled
							value={itemObj ? itemObj.averageCost : ''}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Cantidad en inventario</InputLabel>
						<TextField
							disabled
							value={itemObj ? itemObj.quantity : ''}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Cantidad a solicitar</InputLabel>
						<TextField
							disabled={!itemObj}
							value={quantity}
							onChange={(e) => {
								const value = e.target.value;
								if (isNaN(value)) {
									addNotification({ message: 'La cantidad ingresada es invalida', options: { variant: "error" } });
									return;
								}

								if (value > itemObj.quantity) {
									addNotification({ message: 'La cantidad ingresada es mayor a la existencia', options: { variant: "warning" } });
									return;
								}

								setQuantity(e.target.value);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							variant="contained"
							color="primary"
							onClick={addDetail}
							disabled={!itemObj || itemObj.quantity < quantity}
						>
							Agregar
						</Button>
					</Grid>
				</Grid>
			</Modal>
		);
	};

	const save = async () => {
		const model = {
			transferId: 0,
			branchId: branchId,
			originStorageId: storageId,
			destinationStorageId,
			secretCode: '',
			deliveryDate,
			transferStatusId: 1,
			notes,
			isActive: true,
			details: details.map((i) => ({
				transferDetailId: 0,
				transferId: 0,
				ItemId: i.itemId,
				itemDescription: i.detail,
				ItemMeasurementUnit: i.itemMeasurementUnit,
				ItemAverageCost: i.averageCost,
				QuantityRequested: i.quantity,
				totalAmount: i.quantity,
				notes: '',
				isActive: true,
			})),
		};

		// eslint-disable-next-line
		debugger;

		if (saving || !model.details.length) {
			return;
		}

		setSaving(true);

		try {
			const response = await transfersService.save(model);

			if (!response || !response.data || !response.data.data || !response.data.data || !response.data.data.transferId) {
				props.addNotification({ message: `Error creando solicitud de transferencia`, options: { variant: "error" } });
				setSaving(false);
				return;
			}
			else {
				Swal.fire(
					'Correcto',
					`Solicitud de transferencia creada #${response.data.data.transferId}, clave secreta: ${response.data.data.secretCode}`,
					'success'
				);

				cleanScreen();
			}
		} catch (e) {
			console.log(e);
		}

		setSaving(false);
	};

	const renderForm = () => {
		return (
			<form className="contracts-form" key={id}>
				{renderDetailsModal()}
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Sucursal</InputLabel>
										<Select
											key={branchId}
											isClearable
											value={branchId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setBranchId(selected.value);
												} else {
													setBranchId(null);
													cleanScreen();
												}

												setStorageId(null);
											}}
											onClear={() => {
												setBranchId(null);
											}}
											options={branches}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
										<Grid
											direction="column"
											justify="center"
										>
											<InputLabel>Fecha</InputLabel>
											<DatePicker
												format="DD/MM/YYYY"
												value={deliveryDate}
												onChange={date => setDeliveryDate(date)}
												autoOk
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4} />
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Almacen Origen</InputLabel>
										<Select
											key={branchId}
											disabled={!branchId}
											value={storageId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setStorageId(selected.value);
												} else {
													setStorageId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setStorageId(null);
											}}
											options={storages}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<InputLabel>Almacen Destino</InputLabel>
										<Select
											key={`O${storageId}-D${destinationStorageId}`}
											disabled={!branchId || !storageId}
											value={destinationStorageId}
											onChange={(selected) => {
												if (selected && selected.value) {
													if (storageId == selected.value) {
														addNotification({ message: 'No se puede hacer transferencia al mismo almacen que el de origen', options: { variant: "warning" } });
														setDestinationStorageId(null);
														return;
													}

													setDestinationStorageId(selected.value);
												} else {
													setDestinationStorageId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setDestinationStorageId(null);
											}}
											options={storages}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField
											fullWidth
											label="Notas"
											value={notes}
											onChange={e => {
												setNotes(e.target.value);
											}}
											InputLabelProps={{ shrink: true }}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={classes.root} spacing={1}>
							<Grid item className={classes.item} xs={12}>
								<Table
									title="Detalle"
									isLoading={loading}
									columns={detailColumns}
									items={details}
									options={{
										paging: false,
										search: false,
										selection: false,
									}}
									actions={
										items && items.length > 0 && !id
											? [
												{
													icon: 'add',
													iconProps: { id: 'add-detail' },
													tooltip: "Agregar",
													isFreeAction: true,
													onClick: () => setShowDetailsModal(true),
												},
												{
													icon: 'delete',
													iconProps: { color: 'red' },
													tooltip: "Quitar",
													isFreeAction: false,
													onClick: (e, row) => deleteDetail(e, row),
												}
											]
											: []
									}
								/>
							</Grid>
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{!id && branchId && storageId && destinationStorageId && details && details.length > 0 &&
									<Button variant="outlined" color="primary" onClick={save}>
										{
											new Set(details.map((x) => x.supplierId)).size > 1
												? 'Crear ordenes de compra'
												: 'Crear transferencia'
										}
									</Button>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderTabs = () => {
		return (
			<div>
				<AppBar position="static" color="default">
					<Tabs
						key={id}
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab label="General" icon={<InfoOutlined />} {...tabProps(0)} />
					</Tabs>
				</AppBar>
				<TabPanel value={tab} index={0} boxStyle={{ paddingTop: '0px' }}>
					{renderForm()}
				</TabPanel>
			</div>
		);
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
						? <CircularProgress />
						: renderTabs()
				}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	if (hideLayout) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Crear solicitud de transferencia"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

Transfer.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

Transfer.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(withRouter(withStyles(styles)(Transfer)));
