import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import TransformationsForm from "./../Form";

// UI Components
import { DatePicker } from "@material-ui/pickers";
import {
    withStyles,
    Grid,
    InputLabel,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import inventoriesService from 'services/inventoriesService';
import suppliersService from 'services/suppliersService';
import storagesService from 'services/storagesService';

//Assets
import styles from './styles';
import { formatNumber } from 'utils/numbers';

class RQRequestedToTransform extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().startOf('week').toDate(),
        endDate: Moment.utc().endOf('week').toDate(),
        statusId: 1,
        loading: false,
        branches: [],
        storages: [],
        items: [],
        details: [],
        //date: Moment().add('days', 1).toDate(),
        date: Moment('2021-06-14', 'YYYY-MM-DD').toDate(),
        storageId: null,
        loadingDetails: false,
        open: false,
    };

    columns = [
        {
            title: "Id",
            field: 'itemId',
        },
        {
            title: "Artículo",
            field: 'detail',
        },
        {
            title: "Cantidad en Inventario",
            field: 'inventoryQty',
            render: (row) => <span>{formatNumber(row.inventoryQty)}</span>
        },
        {
            title: "Cantidad Solicitada",
            field: 'requestedQty',
            render: (row) => <span>{formatNumber(row.requestedQty)}</span>
        },
        {
            title: "Cantidad por Transformar",
            field: 'remainingQty',
            render: (row) => <span>{formatNumber(row.remainingQty)}</span>
        },
    ];

    statuses = [
        { value: 0, label: 'Todo' },
        { value: 1, label: 'Nueva' },
        { value: 2, label: 'En Proceso' },
        { value: 3, label: 'En Validación' },
        { value: 4, label: 'Cerrada' },
    ]

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        this.getBranches();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            branchId,
            date,
        } = this.state;

        if (prevState.branchId != branchId) {
            this.getStorages();
        }

        if (prevState.date != date) {
            this.search();
        }
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    getBranches = async () => {
        const response = await suppliersService.getInternal();
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                branches: response.data.data.map((i) => ({
                    value: i.supplierId,
                    label: i.supplierName,
                }))
            });

            if (response.data && response.data.data && response.data.data.length > 0) {
                this.setState({ branchId: response.data.data[0].supplierId });
            }
        }
    }

    getStorages = async () => {
        const {
            branchId
        } = this.state;

        this.setState({ storages: [], storageId: null });

        if (!branchId) {
            return;
        }

        const response = await storagesService.getById(branchId);
        if (!response.ok || response.data.status == "Fail") {
            return;
        }

        let storageId = null;

        if (response && response.data && response.data.data && response.data.data.length) {
            const almacenesCarnes = response.data.data.filter(x => x.name.toUpperCase().includes("CARNE"));
            if (almacenesCarnes && almacenesCarnes.length > 0) {
                storageId = almacenesCarnes[0].storageId;
            }
        }

        this.setState({
            storages: response.data.data.map((i) => ({
                value: i.storageId,
                label: i.name,
            })),
            storageId,
        });
    }

    search = async () => {
        const {
            date,
            branchId,
        } = this.state;

        if (!branchId || !date) {
            return;
        }

        this.setState({ loading: true });
        this.setState({ items: [] });

        try {
            const response = await inventoriesService.getPendingItemsToTransform(date, branchId);
            this.setState({ items: response && response.data ? response.data.data : [] });
        } catch (e) {
            console.log(e);
        }

        this.setState({ loading: false });
    }

    getTitle = () => {
        return "Solicitado por transformar";
    }

    renderFilters = () => {
        const {
            branchId,
            storageId,
            branches,
            storages,
            date,
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Sucursal</InputLabel>
                        <Select
                            key={branches.length}
                            value={branchId}
                            onChange={(selected) => {
                                this.setState({ branchId: selected.value });
                            }}
                            options={branches}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Almacen</InputLabel>
                        <Select
                            disabled
                            isDisabled
                            key={`B${branchId}-S${storageId}`}
                            value={storageId}
                            onChange={(selected) => {
                                this.setState({ storageId: selected.value });
                            }}
                            options={storages}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={date}
                            onChange={v => this.setState({ date: v })}
                            autoOk
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            items,
            selectedItems,
            copiyng,
            loading,
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            search: false,
                            actionsColumnIndex: -1,
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: "Crear hoja de producción del artículo",
                                onClick: (e, r) => this.setState({ open: true, idToShow: r.itemId, quantity: r.remainingQty })
                            },
                            {
                                icon: 'search',
                                isFreeAction: true,
                                tooltip: "Buscar",
                                onClick: this.search,
                            },
                            {
                                icon: 'add',
                                isFreeAction: true,
                                tooltip: "Crear hoja de producción en blanco",
                                onClick: () => this.setState({ open: true, idToShow: null })
                            }
                        ]}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            idToShow,
            quantity,
            open,
            branchId,
            storageId,
        } = this.state;

        return (
            <Modal
                title={`Hoja producción transformación (${!idToShow ? "Manual" : "Automatica"})`}
                isOpen={open}
                onRequestClose={() => {
                    this.setState({ idToShow: null, open: false, quantity: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <TransformationsForm
                    key={idToShow || 0}
                    id={null}
                    itemId={idToShow}
                    quantity={quantity}
                    storageId={storageId}
                    branchId={branchId}
                    hideLayout
                    onClose={() => this.setState({ idToShow: null, open: false, quantity: null })}
                    onSave={() => {
                        this.setState({ idToShow: null, open: false, quantity: null });
                        this.search();
                    }}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(RQRequestedToTransform))))));