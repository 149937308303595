import React from 'react';

// Externals
import PropTypes from 'prop-types';
import tableLocalizations from './localization';
import MaterialTable from "@material-table/core";

// Material helpers
import { withStyles } from '@material-ui/core';
import styles from './styles.jsx';

import { headerStyle, cellStyle } from "./styles.js";

class Table extends React.Component {
    static propTypes = {
        items: PropTypes.array,
        columns: PropTypes.array,
        title: PropTypes.string,
        editOptions: PropTypes.object,
        actions: PropTypes.array,
        options: PropTypes.object,
        style: PropTypes.object
    };

    static defaultProps = {
        editOptions: null,
        title: " ",
        items: [],
        options: {
            pageSize: 15,
            pageSizeOptions: [5, 10, 15, 20],
            search: true
        },
        style: { width: '100%' }
    }

    defaultOptions = {
        pageSize: 15,
        pageSizeOptions: [5, 10, 15, 20, 30],
        search: true,
        rowStyle: cellStyle,
        headerStyle: { ...headerStyle, position: 'sticky', top: 0 },
        maxBodyHeight: 600,
        addRowPosition: "first"
    };

    render() {
        const {
            title,
            items,
            columns,
            editOptions,
            options,
            actions,
            style,
            emptyValue,
            ...rest
        } = this.props;

        let localization = JSON.parse(JSON.stringify(tableLocalizations));
        if (emptyValue) {
            localization.body.emptyDataSourceMessage = emptyValue;
        }

        let completeOptions = {
            ...this.defaultOptions, ...options
        };

        return (
            <MaterialTable
                title={title}
                columns={columns}
                data={items}
                editable={editOptions}
                localization={localization}
                options={completeOptions}
                actions={actions}
                style={style}
                emptyValue={emptyValue}
                {...rest}
            />
        );
    }
}

export default withStyles(styles)(Table);
