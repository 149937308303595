//Assets
import styles from './styles';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfoRounded } from "@material-ui/icons";
import moment from 'moment';

// UI Components
import { withStyles, AppBar, Tab, Tabs, Grid, CircularProgress, Hidden, InputLabel, TextField } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, TabPanel, Table } from 'components';
import { TextField as FormikTextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import { tabProps } from "components/TabPanel";


//Services
import { WithTicket, WithNotifications, WithSession } from "hoc";
import requisitionsService from "services/requisitionsService";
import storagesService from "services/storagesService";
import classificationsService from "services/classificationsService";

class ViewRequisition extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number,
        hideLayout: PropTypes.bool
    };

    static defaultProps = {
        id: null,
        hideLayout: false
    };

    state = {
        selectedItems: [],
        requisition: {
            total: 0,
            totalRequested: 0
        },
        tab: 0,
        isConfirming: false,
        storages: [],
        classifications: [],
        storageIds: new Set(),
        classificationIds: new Set(),
        storageId: 0,
        classificationId: 0
    };

    columns = [
        {
            title: "Descripción",
            field: 'itemPresentation.description',
            render: rowData => (
                <span className={this.props.classes.itemDescription}>
                    {rowData.itemPresentation.sapCode} - {this.getPresentationName(rowData.itemPresentation)}
                </span>
            )
        },
        {
            title: "Solicitado",
            field: 'quantity',
            render: rowData => <span>{rowData.quantity} {rowData.measurementUnit.abbreviation}</span>
        },
        {
            title: "Costo",
            render: row => <span>${row.price}</span>
        },
        {
            title: "Observaciones",
            field: 'notes',
        }
    ];

    componentDidMount = async () => {
        //this.getCatalogs();
        this.getRequisitionData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.storageId != this.state.storageId)
            this.setState({ classificationId: 0 });
    }

    getRequisitionData = async () => {
        const {
            id
        } = this.props;

        let idToSearch = id;
        if (!idToSearch) {
            idToSearch = this.props.match.params.id;
        }

        const response = await requisitionsService.getByIdWithDetails(idToSearch);
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                requisition: {
                    ...response.data.data,
                    type: 'Pedido',
                    deliveryDateFormatted: moment(response.data.data.deliveryDate).format("DD/MM/YYYY"),
                    totalRequested: response.data.data.supplierRequisitionDetails.reduce((sum, i) => sum + i.total, 0),
                    supplierRequisitionDetails: response.data.data.supplierRequisitionDetails.map((i, index) => {
                        i.index = index;

                        if (!i.suppliedQuantity)
                            i.suppliedQuantity = "";

                        this.getRowTotal(i);

                        return i;
                    })
                }
            }, () => {
                let storageIds = new Set();
                let classificationIds = new Set();
                this.state.requisition.supplierRequisitionDetails.forEach(i => {
                    storageIds.add(i.supplierItem.storageId);
                    classificationIds.add(i.supplierItem.clasificationId);
                });

                this.setState({ storageIds, classificationIds }, () => {
                });

                window.setTimeout(() => {
                    this.previousFocus(1);
                }, 500);
            });
        }
    }

    getCatalogs = async () => {
        const storagesResponse = await storagesService.getAll();
        const classificationsResponse = await classificationsService.getAll();

        if (storagesResponse.ok && storagesResponse.data.status != "Fail") {
            const storages = storagesResponse.data.data.map(i => ({ value: i.storageId, label: i.name }));
            this.setState({ storages: [{ value: 0, label: "TODOS" }, ...storages] });
        }

        if (classificationsResponse.ok && classificationsResponse.data.status != "Fail") {
            const classifications = classificationsResponse.data.data.map(i => ({
                value: i.clasificationId, label: i.name, storageId: i.storageId
            }));
            this.setState({ classifications: [{ value: 0, label: "TODOS" }, ...classifications] });
        }
    }

    getPresentationName = (itemPresentation) => {
        if (itemPresentation.itemDescription != itemPresentation.description)
            return `${itemPresentation.itemDescription} ** ${itemPresentation.description}`;
        else
            return itemPresentation.itemDescription;
    }

    getRowTotal = (detail) => {
        if (!detail.suppliedQuantity || isNaN(detail.suppliedQuantity)) {
            detail.newTotal = 0;
            return 0;
        }

        const newValue = parseFloat(detail.suppliedQuantity);
        const newTotal = (newValue * detail.price).toFixed(2);
        detail.newTotal = newTotal;
        return newTotal;
    }

    renderRowInput = (row) => {
        return (
            <TextField
                inputRef={i => this[`input${row.index}`] = i}
                type="text"
                value={row.suppliedQuantity}
                onChange={(e) => {
                    const value = e.target.value;

                    if (isNaN(value))
                        return;

                    const details = this.state.requisition.supplierRequisitionDetails;
                    const detail = details.find(x => x.id == row.id);
                    detail.suppliedQuantity = e.target.value;
                    this.getRowTotal(detail);
                    this.setState(ps => ({ requisition: { ...ps.requisition, supplierRequisitionDetails: details } }));
                }}
                onKeyPress={async (e) => {
                    if (e.key == "Enter") {
                        e.preventDefault();
                        this.nextFocus(row.index);
                    }
                }}
                onKeyDown={e => {
                    if (e.keyCode == 38) { //Up
                        e.preventDefault();
                        this.previousFocus(row.index);
                    }
                    else if (e.keyCode == 40) { //Down
                        e.preventDefault();
                        this.nextFocus(row.index);
                    }
                }}
            />
        );
    }

    renderRowCommentInput = (row) => {
        return <TextField
            type="text"
            value={row.comments}
            multiline
            fullWidth
            margin="dense"
            rowsMax="4"
            onChange={(e) => {
                const details = this.state.requisition.supplierRequisitionDetails;
                const detail = details.find(x => x.id == row.id);
                detail.comments = e.target.value;
                this.setState(ps => ({ requisition: { ...ps.requisition, supplierRequisitionDetails: details } }));
            }}
               />;
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            requisition: {
                supplierRequisitionDetails: details
            }
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    renderTabs = () => {
        const {
            tab,
        } = this.state;

        return (
            <>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={(e, tab) => {
                            this.setState({ tab });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            icon={<InfoRounded />}
                            {...tabProps(0)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0} boxStyle={{ paddingTop: "0px" }}>
                    {this.renderForm()}
                </TabPanel>
            </>
        );
    }

    renderForm = () => {
        const {
            classes
        } = this.props;

        const {
            requisition,
            storageId,
            classificationId
        } = this.state;

        let items = [];

        if (requisition.supplierRequisitionDetails) {
            items = requisition.supplierRequisitionDetails;
        }

        if (storageId && storageId != 0) {
            items = items.filter(i => i.supplierItem.storageId == storageId);
        }

        if (classificationId && classificationId != 0) {
            items = items.filter(i => i.supplierItem.clasificationId == classificationId);
        }

        return (
            <Formik
                enableReinitialize
                initialValues={requisition}
                onSubmit={async (values, { setSubmitting }) => {
                    await this.save(values);
                    setSubmitting(false);
                }}
            >
                <Form>
                    <Grid container className={classes.root} spacing={1}>
                        <Hidden only="xs">
                            <Grid item className={classes.item} md={7} lg={8}>
                                <Field
                                    type="text"
                                    label="Folio"
                                    name="folio"
                                    margin="normal"
                                    component={FormikTextField}
                                    fullWidth
                                    disabled
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item xs={12} className={classes.item}>
                                <Field
                                    name="folio"
                                >
                                    {form => this.renderLabel(form, "Folio")}
                                </Field>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                            <Field name="lunchRoomName">
                                {form => this.renderLabel(form, "Unidad")}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                            <Field name="deliveryDateFormatted">
                                {form => this.renderLabel(form, "Dia de entrega")}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                            <Field name="totalRequested">
                                {form => this.renderLabel(form, "Total solicitado", "currency")}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                            <Field name="eventId">
                                {form => this.renderLabel(form, "Evento")}
                            </Field>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                style={{ paddingLeft: '15px', paddingRight: "15px" }}
                            >
                                <Table
                                    columns={this.columns}
                                    items={items}
                                    options={{
                                        paging: false,
                                        search: false,
                                        toolBar: false
                                    }}
                                    components={{
                                        Toolbar: () => (
                                            <div>
                                                {/* <MTableToolbar {...props} /> */}
                                            </div>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        );
    }

    renderLabel = (form, label, type) => {
        const {
            classes
        } = this.props;

        return (
            <div className={classes.selectRoot}>
                <InputLabel>{label}</InputLabel>
                <TextField
                    value={type == 'currency' ? "$ " + parseFloat(form.field.value || 0).toFixed(2) : type == "number" ? parseFloat(form.field.value || 0).toFixed(2) : form.field.value}
                    style={{ width: '100%' }}
                />
            </div>
        );
    }

    renderView = () => {
        const {
            loading
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Portlet className={classes.root}>
                <PortletContent noPadding>
                    {
                        loading && <CircularProgress />
                    }
                    {
                        !loading && this.renderTabs()
                    }
                </PortletContent>
            </Portlet>
        );
    }

    render() {
        const {
            classes,
            hideLayout
        } = this.props;

        if (hideLayout)
            return this.renderView();

        return (
            <DashboardLayout title={"Ver requisición"}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        {this.renderView()}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(WithNotifications(WithSession(WithTicket(withStyles(styles)(ViewRequisition)))));