import API from './Api';

class PurchasesService extends API {
	constructor(controller) {
		super(controller);
	}

	getById = (id) => {
		return this.API.get(`${this.controller}/${id}`);
	};

	cancel = (id, reason) => {
		return this.API.post(`${this.controller}/${id}/cancel?reason=${reason}`);
	};

	create = (model) => {
		return this.API.post(`${this.controller}`, model);
	};

	createMulti = (model) => {
		return this.API.post(`${this.controller}/create-by-supplier`, model);
	};

	search = (model) => {
		return this.API.post(`${this.controller}/search`, model);
	};

	searchReceive = (model) => {
		return this.API.post(`${this.controller}/search-for-receive`, model);
	};
}

let service = new PurchasesService('purchase');

export default service;
